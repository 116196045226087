import { useEffect, useState } from "react";
import {
  useCMSBlogs,
  useCMSProductionPhoto,
  useCMSTestimonials,
} from "./useCMS";
import { useMicroserviceShowBrand } from "./useMicroserviceShows";
import useService from "./useService";

export const useShowVariantOverviewEmpty = ({
  showVariantId,
  showBrandId,
}: {
  showVariantId?: string;
  showBrandId?: string;
}) => {
  const testimonials = useCMSTestimonials(
    {
      filter: {
        showVariantTags: [showVariantId as string],
        showBrandTags: [showBrandId as string],
      },
    },
    Boolean(showBrandId)
  );

  const blogs = useCMSBlogs(
    {
      filter: {
        showVariantTags: [showVariantId as string],
        showBrandTags: [showBrandId as string],
      },
    },
    Boolean(showBrandId)
  );

  const images = useCMSProductionPhoto(
    !showVariantId || !showBrandId
      ? {}
      : {
          filter: {
            showVariantTags: [showVariantId as string],
            showBrandTags: [showBrandId as string],
          },
        },
    Boolean(showBrandId)
  );

  return {
    empty:
      testimonials.loading || blogs.loading || images.loading
        ? false
        : testimonials.testimonials.length === 0 &&
          blogs.blogs.length === 0 &&
          images.images.length === 0,
    loading: testimonials.loading || blogs.loading || images.loading,
  };
};

export const requestShowBrandOnlyShowSSR = async (showBrandSlug: string) => {
  const makeRequestToMicroservice = useService("microservice");

  const data = await makeRequestToMicroservice(
    "GET",
    `/backstage/show-brands/slug/${showBrandSlug}`,
    {
      "includes[attachments]": "true",
      "includes[shows]": "true",
      "includes[shows_attachments]": "true",
    }
  );

  return { only: data?.["shows"]?.length === 1 };
};

export const useShowBrandOnlyShow = (showBrandSlug?: string) => {
  const [only, _only] = useState(false);
  const [loading, _loading] = useState(true);

  useEffect(() => {
    if (!showBrandSlug) {
      return;
    }

    requestShowBrandOnlyShowSSR(showBrandSlug).then((data) => {
      _only(data.only);
      _loading(false);
    });
  }, [showBrandSlug]);

  return { only, loading };
};

export default useShowVariantOverviewEmpty;
