import { array, boolean, object, string } from "yup";

import { BillingAddressSchema } from "./billingAddressSchema";
import { DiscountSchema } from "./discountSchema";
import { LicensedItemSchema } from "./licensedItemSchema";
import { NonLicensedItemSchema } from "./nonLicensedItemSchema";
import { ProductionSchema } from "./productionSchema";
import { ShippingAddressSchema } from "./shippingAddressSchema";
import { SubtotalSchema } from "./subtotalSchema";

export const ShoppingCartSchema = object({
  id: string(),
  isProposal: boolean(),
  proposal: object().shape({ id: string() }).nullable(),
  downloadedInvoice: boolean(),
  createdAt: string().nullable(),
  updatedAt: string().nullable(),
  region: object().unknown(),
  customer: object().unknown(),
  representativeRegion: object().unknown().nullable(),
  billingAddress: BillingAddressSchema,
  shippingAddress: ShippingAddressSchema,
  productions: array().of(ProductionSchema),
  discounts: array().of(DiscountSchema).optional(),
  status: string().nullable(),
  representative: object().unknown(),
  licensedItems: array().of(LicensedItemSchema),
  nonLicensedItems: array().of(NonLicensedItemSchema),
  subtotalLicensed: SubtotalSchema,
  subtotalNonLicensed: SubtotalSchema,
  freight: object({
    available: boolean(),
    freight: string(),
    rushFee: string(),
    expeditedFee: string(),
    subtotal: string(),
  }),
  totalInclusiveTaxes: string(),
  totalNonInclusiveTaxes: string(),
  total: string(),
  totalBillable: string(),
  totalDiscount: string(),
  totalOvationDeduction: string(),
  totalOvationPoints: string(),
});
