import { DesignedButton, DesignedCard, Rule } from "@with-nx/simple-ui/atoms";
import dynamic from "next/dynamic";
import { useCallback, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

import { EditorSyncFiles } from "../EditorSync";
import ShowSync from "../ShowSync";
import { CueSheet, CueSheetItem, ShowData } from "../types/editor";

const EditorPlayerController = dynamic(import("./EditorPlayerController"), {
  ssr: false,
});

export const EditorPlayer = ({
  show,
  files,
}: {
  show: ShowData;
  files: EditorSyncFiles;
}) => {
  try {
    const [sheet, _sheet] = useState<CueSheet>(
      ShowSync.transformShowDataToCueSheet(show)
    );

    const next = useCallback(() => {
      if (Number(sheet.current || 0) < Number(sheet.items.length - 1 || 0)) {
        _sheet((s) => ({
          ...s,
          current: Number(s.current || 0) + 1,
          blackout: false,
        }));
      } else {
        _sheet((s) => ({
          ...s,
          current: 0,
          blackout: false,
        }));
      }
    }, [sheet]);

    const blackout = useCallback(() => {
      _sheet((s) => ({
        ...s,
        blackout: true,
      }));
    }, [sheet]);

    const thumbnail = useCallback(() => {
      if (sheet.items[sheet.current || 0 + 1]) {
        return sheet.items[sheet.current || 0 + 1];
      } else {
        return {
          type: "blackout",
          identifier: "blackout",
          name: "Blackout",
        } as CueSheetItem;
      }
    }, [sheet]);

    return (
      <Box>
        <Box parse="w:100% mb:24">
          <Box parse="d:flex mb:16">
            <Rule parse="!ll d:block mr:16" color="var(--positive)">
              Active
            </Rule>
            <Box parse="d:inline-flex fd:column a:flex-start">
              <Rule parse="!ls d:block" color="var(--font3)">
                {`Cue ${Number(sheet.current || 0) + 1}`}
              </Rule>
              <Rule parse="!lt d:block" color="var(--font4)">
                {sheet?.items[sheet.current || 0]?.name}
              </Rule>
            </Box>
          </Box>

          <Box
            parse={"w:100% p:relative br:5 oa:visible pt:56.25% br:5 oa:hidden"}
          >
            <EditorPlayerController files={files} sheet={sheet} />
          </Box>
        </Box>
        <Box parse="w:100% mb:24">
          <Box parse="d:flex mb:16">
            <Rule parse="!ll d:block mr:16" color="var(--primary)">
              Next
            </Rule>
            <Box parse="d:inline-flex fd:column a:flex-start">
              <Rule parse="!ls d:block" color="var(--font3)">
                {`Cue ${Number(sheet.current || 0) + 2}`}
              </Rule>
              <Rule parse="!lt d:block" color="var(--font4)">
                {thumbnail?.()?.name}
              </Rule>
            </Box>
          </Box>

          <DesignedCard
            size="16x9"
            image={
              typeof thumbnail?.()?.set?.thumbnail === "string"
                ? files?.[thumbnail?.()?.set?.thumbnail as string]
                : undefined
            }
          />
        </Box>
        <Box parse="mb:12">
          <DesignedButton
            theme="positive"
            label="Next"
            press={next}
            properties={{
              style: {
                width: "100%",
                flex: 1,
              },
            }}
            container={{
              style: {
                width: "100%",
              },
            }}
          />
        </Box>
        <Box parse="mb:12">
          <DesignedButton
            theme="negative"
            label="Blackout Now"
            press={blackout}
            disable={sheet.blackout}
            properties={{
              style: {
                width: "100%",
                flex: 1,
              },
            }}
            container={{
              style: {
                width: "100%",
              },
            }}
          />
        </Box>
      </Box>
    );
  } catch (error) {
    console.error(error);
    return <span>Error</span>;
  }
};

export default EditorPlayer;
