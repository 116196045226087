/* eslint-disable @typescript-eslint/no-explicit-any */
interface PipedriveDealProps {
  email: string;
  phone?: string;
  name?: string;
  surname?: string;
  organization?: string;
  data: [string, string][];
}

interface PipedriveLeadProps {
  origin: string;
  email: string;
  phone?: string;
  name?: string;
  surname?: string;
  organization?: string;
  data: [string, string][];
}

interface PipedriveInquiryProps {
  isReturningCustomer: boolean;
  name: string;
  surname: string;
  email: string;
  phone: string;
  organization: string;
  businessType: number;
  region: "US" | "CA" | "GB/Europe" | "AU/NZ" | "OTHER";
  state?: string;
  show: string;
  message: string;
  openingNight: string;
  closingNight: string;
  additionalData:
    | {
        [key: string]: string;
      }
    | [string, string][];
  subject: string;
}

export class Pipedrive {
  static async deal(props: PipedriveDealProps) {
    return await this.communicate({
      path: "/pipedrive/deals",
      params: props,
      method: "POST",
    });
  }

  static async lead(props: PipedriveLeadProps) {
    return await this.communicate({
      path: "/pipedrive/leads",
      params: props,
      method: "POST",
    });
  }

  static async inquiry(props: PipedriveInquiryProps) {
    return await this.communicate({
      path: "/ecommerce/inquires",
      params: props,
      method: "POST",
    });
  }

  static get API() {
    return (
      process.env["NEXT_PUBLIC_MICROSERVICE_API_ORIGIN"] ||
      "http://localhost:3000"
    );
  }

  static async communicate({
    path,
    params,
    method,
  }: {
    path: string;
    params?: any;
    method?: string;
  }) {
    try {
      const url = new URL(path, this.API);

      if (method === "GET") {
        url.search = new URLSearchParams(params).toString();
      }

      const response = await fetch(url.toString(), {
        method: method || "GET",
        headers: {
          "Content-Type": "application/json",
        },
        body: method === "POST" ? JSON.stringify(params) : null,
      });
      const parsed = await response.json();

      return parsed;
    } catch (error) {
      return false;
    }
  }
}

export default Pipedrive;
