/* eslint-disable @typescript-eslint/no-explicit-any */
import { ThrottlerController } from "@with-nx/hooks-n-helpers";
import Moment from "moment";
import { useCallback, useEffect, useState } from "react";

import useService from "./useService";

export type UseCMSWorkshop = {
  _id: string;
  title?: string;
  description?: string;
  image?: string;
  buttonText?: string;
  buttonURL?: string;
};

export const useCMSWorkshops = (
  {
    page = 0,
    per = 50,
  }: {
    page?: number;
    per?: number;
  },
  ready?: boolean
) => {
  const makeRequestToCMS = useService("cms", {
    cache: 1_800_000,
    timeout: 10_000,
    retry: 3,
  });
  const [data, _data] = useState<UseCMSWorkshop[]>([]);
  const [pages, _pages] = useState<number>(0);
  const [loading, _loading] = useState<boolean>(true);

  const hydrate = useCallback(() => {
    if (ready === false) {
      return;
    }

    (async () => {
      const params: any = {
        page,
        per,
      };

      const request = (await makeRequestToCMS(
        "POST",
        "/api/v1/workshop/filter",
        params
      )) as any;

      if (request.data) {
        _pages(request.pages);
        _data(request.data);
        _loading(false);
      }
    })();
  }, [page, per, ready]);

  useEffect(() => {
    hydrate();
  }, [page, per, ready]);

  return {
    workshops: data,
    pages,
    loading,
    page,
  };
};

export type UseCMSWriter = {
  _id: string;
  title?: string;
  description?: string;
  image?: string;
};

export const useCMSWriters = (
  {
    page = 0,
    per = 50,
  }: {
    page?: number;
    per?: number;
  },
  ready?: boolean
) => {
  const makeRequestToCMS = useService("cms", {
    cache: 1_800_000,
    timeout: 10_000,
    retry: 3,
  });
  const [data, _data] = useState<UseCMSWriter[]>([]);
  const [pages, _pages] = useState<number>(0);
  const [loading, _loading] = useState<boolean>(true);

  const hydrate = useCallback(() => {
    if (ready === false) {
      return;
    }

    (async () => {
      const params: any = {
        page,
        per,
      };

      const request = (await makeRequestToCMS(
        "POST",
        "/api/v1/writer/filter",
        params
      )) as any;

      if (request.data) {
        _pages(request.pages);
        _data(request.data);
        _loading(false);
      }
    })();
  }, [page, per, ready]);

  useEffect(() => {
    hydrate();
  }, [page, per, ready]);

  return {
    writers: data,
    pages,
    loading,
    page,
  };
};

export type UseCMSSlide = {
  _id: string;
  title?: string;
  description?: string;
  order?: string;
  buttonText?: string;
  buttonURL?: string;
  coverImage?: string;
  logoImage?: string;
  regionTags?: string;
  pageTag?: string[];
};

export const useCMSSlides = (
  {
    page = 0,
    per = 50,
  }: {
    page?: number;
    per?: number;
  },
  ready?: boolean
) => {
  const makeRequestToCMS = useService("cms", {
    cache: 1_800_000,
    debug: true,
    timeout: 10_000,
    retry: 3,
  });
  const [data, _data] = useState<UseCMSSlide[]>([]);
  const [pages, _pages] = useState<number>(0);
  const [loading, _loading] = useState<boolean>(true);

  const hydrate = useCallback(() => {
    if (ready === false) {
      return;
    }

    (async () => {
      const params: any = {
        page,
        per,
      };

      const request = (await makeRequestToCMS(
        "POST",
        "/api/v1/slide/filter",
        params
      )) as any;

      if (request.data) {
        _pages(request.pages);
        _data(request.data);
        _loading(false);
      }
    })();
  }, [page, per, ready]);

  useEffect(() => {
    hydrate();
  }, [page, per, ready]);

  return {
    slides: data.sort((a, b) => {
      return Number(a?.order || "0") - Number(b?.order || "0");
    }),
    pages,
    loading,
    page,
  };
};

export type UseCMSPromotion = {
  _id: string;
  title?: string;
  subtitle?: string;
  slug?: string;
  image?: string;
  description?: string;
  content?: any;
  licensorTags?: string[];
  solutionTags?: string[];
  regionTags?: string[];
};

export const useCMSPromotions = (
  {
    page = 0,
    per = 50,
  }: {
    page?: number;
    per?: number;
  },
  ready?: boolean
) => {
  const makeRequestToCMS = useService("cms", {
    cache: 1_800_000,
    timeout: 10_000,
    retry: 3,
  });
  const [data, _data] = useState<UseCMSPromotion[]>([]);
  const [pages, _pages] = useState<number>(0);
  const [loading, _loading] = useState<boolean>(true);

  const hydrate = useCallback(() => {
    if (ready === false) {
      return;
    }

    (async () => {
      const params: any = {
        page,
        per,
      };

      const request = (await makeRequestToCMS(
        "POST",
        "/api/v1/promotion/filter",
        params
      )) as any;

      if (request.data) {
        _pages(request.pages);
        _data(request.data);
        _loading(false);
      }
    })();
  }, [page, per]);

  useEffect(() => {
    hydrate();
  }, [page, per]);

  return {
    promotions: data,
    pages,
    loading,
    page,
  };
};

export type UseCMSGroup = {
  _id: string;
  title?: string;
  description?: string;
  coverImage?: string;
  buttonURL?: string;
  buttonText?: string;
  groupType?: string;
};

export const useCMSGroups = (
  {
    page = 0,
    per = 50,
  }: {
    page?: number;
    per?: number;
  },
  ready?: boolean
) => {
  const makeRequestToCMS = useService("cms", {
    cache: 1_800_000,
    timeout: 10_000,
    retry: 3,
  });
  const [data, _data] = useState<UseCMSGroup[]>([]);
  const [pages, _pages] = useState<number>(0);
  const [loading, _loading] = useState<boolean>(true);

  const hydrate = useCallback(() => {
    if (ready === false) {
      return;
    }

    (async () => {
      const params: any = {
        page,
        per,
      };

      const request = (await makeRequestToCMS(
        "POST",
        "/api/v1/group/filter",
        params
      )) as any;

      if (request.data) {
        _pages(request.pages);
        _data(request.data);
        _loading(false);
      }
    })();
  }, [page, per, ready]);

  useEffect(() => {
    hydrate();
  }, [page, per, ready]);

  return {
    groups: data,
    pages,
    loading,
    page,
  };
};

export type UseCMSFeatured = {
  _id: string;
  name?: string;
  url?: string;
  image?: string;
  regionTags?: string[];
  partnerTags?: string[];
  pageTags?: string[];
  logoColor?: string;
};

export const useCMSFeatureds = (
  {
    page = 0,
    per = 50,
  }: {
    page?: number;
    per?: number;
  },
  ready?: boolean
) => {
  const makeRequestToCMS = useService("cms", {
    cache: 1_800_000,
    timeout: 10_000,
    retry: 3,
  });
  const [data, _data] = useState<UseCMSFeatured[]>([]);
  const [pages, _pages] = useState<number>(0);
  const [loading, _loading] = useState<boolean>(true);

  const hydrate = useCallback(() => {
    if (ready === false) {
      return;
    }

    (async () => {
      const params: any = {
        page,
        per,
      };

      const request = (await makeRequestToCMS(
        "POST",
        "/api/v1/featured/filter",
        params
      )) as any;

      if (request.data) {
        _pages(request.pages);
        _data(request.data);
        _loading(false);
      }
    })();
  }, [page, per, ready]);

  useEffect(() => {
    hydrate();
  }, [page, per, ready]);

  return {
    featureds: data,
    pages,
    loading,
    page,
  };
};

export type UseCMSPartner = {
  _id: string;
  name?: string;
  slug?: string;
  description?: string;
  image?: string;
  cover?: string;
  regionTags?: string[];
  solutionTags?: string[];
  partnerTypeTags?: string[];
};

export const fetchCMSPartnersSSR = async ({
  page = 0,
  per = 50,
}: {
  page?: number;
  per?: number;
}) => {
  const makeRequestToCMS = useService("cms", {
    timeout: 10_000,
    retry: 3,
  });

  const params: any = {
    page,
    per,
  };

  const request = (await makeRequestToCMS(
    "POST",
    "/api/v1/partner/filter",
    params
  )) as any;

  return request.data;
};

export const useCMSPartners = (
  {
    page = 0,
    per = 50,
  }: {
    page?: number;
    per?: number;
  },
  ready?: boolean
) => {
  const makeRequestToCMS = useService("cms", {
    cache: 1_800_000,
    timeout: 10_000,
    retry: 3,
  });
  const [data, _data] = useState<UseCMSPartner[]>([]);
  const [pages, _pages] = useState<number>(0);
  const [loading, _loading] = useState<boolean>(true);

  const hydrate = useCallback(() => {
    if (ready === false) {
      return;
    }

    (async () => {
      const params: any = {
        page,
        per,
      };

      const request = (await makeRequestToCMS(
        "POST",
        "/api/v1/partner/filter",
        params
      )) as any;

      if (request.data) {
        _pages(request.pages);
        _data(request.data);
        _loading(false);
      }
    })();
  }, [page, per, ready]);

  useEffect(() => {
    hydrate();
  }, [page, per, ready]);

  return {
    partners: data,
    pages,
    loading,
    page,
  };
};

export type UseCMSAnnouncement = {
  _id: string;
  title?: string;
  badge?: string;
  url?: string;
  color?: string;
  background?: string;
  regionTags?: string[];
};

export const useCMSAnnouncements = (
  {
    page = 0,
    per = 50,
    regex,
    search,
    filter,
    exists,
  }: {
    page?: number;
    per?: number;
    search?: string;
    regex?: number;
    filter?: {
      regionTags?: string[];
      _id?: string[];
    };
    exists?: {
      regionTags?: true;
    };
  },
  ready?: boolean
) => {
  const makeRequestToCMS = useService("cms", {
    cache: 1_800_000,
    timeout: 10_000,
    retry: 3,
  });
  const [data, _data] = useState<UseCMSAnnouncement[]>([]);
  const [pages, _pages] = useState<number>(0);
  const [loading, _loading] = useState<boolean>(true);

  const hydrate = useCallback(() => {
    if (ready === false) {
      return;
    }

    if (!ThrottlerController.can("announcements", "reload", 1000)) {
      return;
    }

    (async () => {
      const closest10Minute =
        Math.floor(new Date().getTime() / 600000) * 600000;
      const estDate = Moment(closest10Minute)
        .utc()
        .utcOffset(-5)
        .format("YYYY-MM-DDTHH:mm");

      const params: any = {
        page,
        per,
        filter: {
          expiration: {
            $gte: estDate,
          },
          regionTags: filter?.regionTags?.[0] || "1",
        },
      };

      const request = (await makeRequestToCMS(
        "POST",
        "/api/v1/announcement/filter",
        params
      )) as any;

      if (request.data) {
        _pages(request.pages);
        _data(request.data);
        _loading(false);
      }
    })();
  }, [page, per, search, filter, exists, regex, ready]);

  useEffect(() => {
    hydrate();
  }, [page, per, search, ready]);

  useEffect(() => {
    hydrate();
  }, [JSON.stringify({ filter, exists, regex })]);

  return {
    announcements: data,
    pages,
    loading,
    page,
    random: data[Math.floor(Math.random() * data.length)],
  };
};

export type UseCMSClient = {
  _id: string;
  title?: string;
  slug?: string;
  image?: string;
  description?: string;
  awards?: string;
  updatedAt?: string;
  createdAt?: string;
  content?: any;
  plainTags?: string[];
  digitalDropTags?: string[];
  licensorTags?: string[];
  scenicProjectionTags?: string[];
  showVariantTags?: string[];
  showBrandTags?: string[];
  choreoGuideTags?: string[];
  solutionTags?: string[];
  projectorTags?: string[];
  clientType?: string;
};

export const fetchCMSClientsSSR = async (options?: {
  page?: number;
  per?: number;
  search?: string;
  regex?: number;
  filter?: {
    plainTags?: string[];
    digitalDropTags?: string[];
    licensorTags?: string[];
    scenicProjectionTags?: string[];
    showVariantTags?: string[];
    showBrandTags?: string[];
    choreoGuideTags?: string[];
    solutionTags?: string[];
    projectorTags?: string[];
    storyTypeTags?: string[];
    _id?: string[];
    slug?: string[];
  };
  exists?: {
    plainTags?: true;
    digitalDropTags?: true;
    licensorTags?: true;
    scenicProjectionTags?: true;
    showVariantTags?: true;
    showBrandTags?: true;
    choreoGuideTags?: true;
    solutionTags?: true;
    projectorTags?: true;
    storyTypeTags?: true;
  };
}) => {
  const { page = 0, per = 50, regex, search, filter, exists } = options || {};

  const makeRequestToCMS = useService("cms", {
    timeout: 10_000,
    retry: 3,
  });

  const params: any = {
    page,
    per,
    search,
    regex,
  };

  const groups = [
    "plainTags",
    "digitalDropTags",
    "licensorTags",
    "scenicProjectionTags",
    "showVariantTags",
    "showBrandTags",
    "choreoGuideTags",
    "solutionTags",
    "projectorTags",
    "storyTypeTags",
  ];

  Object.entries(filter || {}).map(([key, value]) => {
    if (!params.filter) {
      params.filter = {};
    }

    params.filter[key] =
      Array.isArray(value) && groups.includes(key)
        ? {
            $in: value,
          }
        : value;
  });

  if (exists) {
    Object.entries(exists).map(([key]) => {
      if (!params.filter) {
        params.filter = {};
      }

      params.filter[key] = { $exists: true, $not: { $size: 0 } };
    });
  }

  const request = (await makeRequestToCMS(
    "POST",
    "/api/v1/client/filter",
    params
  )) as any;

  return request.data;
};

export const useCMSClients = (
  options?: {
    page?: number;
    per?: number;
    search?: string;
    regex?: number;
    filter?: {
      plainTags?: string[];
      digitalDropTags?: string[];
      licensorTags?: string[];
      scenicProjectionTags?: string[];
      showVariantTags?: string[];
      showBrandTags?: string[];
      choreoGuideTags?: string[];
      solutionTags?: string[];
      projectorTags?: string[];
      storyTypeTags?: string[];
      _id?: string[];
      slug?: string[];
    };
    exists?: {
      plainTags?: true;
      digitalDropTags?: true;
      licensorTags?: true;
      scenicProjectionTags?: true;
      showVariantTags?: true;
      showBrandTags?: true;
      choreoGuideTags?: true;
      solutionTags?: true;
      projectorTags?: true;
      storyTypeTags?: true;
    };
  },
  ready?: boolean
) => {
  const { page = 0, per = 50, regex, search, filter, exists } = options || {};

  const makeRequestToCMS = useService("cms", {
    cache: 1_800_000,
    timeout: 10_000,
    retry: 3,
  });
  const [data, _data] = useState<UseCMSClient[]>([]);
  const [pages, _pages] = useState<number>(0);
  const [loading, _loading] = useState<boolean>(true);

  const hydrate = useCallback(() => {
    if (ready === false) {
      return;
    }

    (async () => {
      const params: any = {
        page,
        per,
        search,
        regex,
      };

      const groups = [
        "plainTags",
        "digitalDropTags",
        "licensorTags",
        "scenicProjectionTags",
        "showVariantTags",
        "showBrandTags",
        "choreoGuideTags",
        "solutionTags",
        "projectorTags",
        "storyTypeTags",
      ];

      Object.entries(filter || {}).map(([key, value]) => {
        if (!params.filter) {
          params.filter = {};
        }

        params.filter[key] =
          Array.isArray(value) && groups.includes(key)
            ? {
                $in: value,
              }
            : value;
      });

      if (exists) {
        Object.entries(exists).map(([key]) => {
          if (!params.filter) {
            params.filter = {};
          }

          params.filter[key] = { $exists: true, $not: { $size: 0 } };
        });
      }

      const request = (await makeRequestToCMS(
        "POST",
        "/api/v1/client/filter",
        params
      )) as any;

      if (request.data) {
        _pages(request.pages);
        _data(request.data);
        _loading(false);
      }
    })();
  }, [page, per, search, filter, exists, regex, ready]);

  useEffect(() => {
    if (ready === false) {
      return;
    }

    hydrate();
  }, [ready]);

  return { clients: data, pages, loading, page };
};

export const useCMSRandomClients = (
  options?: {
    page?: number;
    per?: number;
    search?: string;
    regex?: number;
    filter?: {
      plainTags?: string[];
      digitalDropTags?: string[];
      licensorTags?: string[];
      scenicProjectionTags?: string[];
      showVariantTags?: string[];
      showBrandTags?: string[];
      choreoGuideTags?: string[];
      solutionTags?: string[];
      projectorTags?: string[];
      storyTypeTags?: string[];
      _id?: string[];
      slug?: string[];
    };
    exists?: {
      plainTags?: true;
      digitalDropTags?: true;
      licensorTags?: true;
      scenicProjectionTags?: true;
      showVariantTags?: true;
      showBrandTags?: true;
      choreoGuideTags?: true;
      solutionTags?: true;
      projectorTags?: true;
      storyTypeTags?: true;
    };
  },
  ready?: boolean
) => {
  const { page = 0, per = 50, regex, search, filter, exists } = options || {};

  const makeRequestToCMS = useService("cms", {
    cache: 1_800_000,
    timeout: 10_000,
    retry: 3,
  });
  const [data, _data] = useState<UseCMSClient[]>([]);
  const [pages, _pages] = useState<number>(0);
  const [loading, _loading] = useState<boolean>(true);

  const hydrate = useCallback(() => {
    if (ready === false) {
      return;
    }

    (async () => {
      const params: any = {
        page,
        per,
        search,
        regex,
      };

      const groups = [
        "plainTags",
        "digitalDropTags",
        "licensorTags",
        "scenicProjectionTags",
        "showVariantTags",
        "showBrandTags",
        "choreoGuideTags",
        "solutionTags",
        "projectorTags",
        "storyTypeTags",
      ];

      Object.entries(filter || {}).map(([key, value]) => {
        if (!params.filter) {
          params.filter = {};
        }

        params.filter[key] =
          Array.isArray(value) && groups.includes(key)
            ? {
                $in: value,
              }
            : value;
      });

      if (exists) {
        Object.entries(exists).map(([key]) => {
          if (!params.filter) {
            params.filter = {};
          }

          params.filter[key] = { $exists: true, $not: { $size: 0 } };
        });
      }

      const request = (await makeRequestToCMS(
        "POST",
        "/api/v1/client/sample",
        params
      )) as any;

      if (request.data) {
        _pages(request.pages);
        _data(request.data);
        _loading(false);
      }
    })();
  }, [page, per, search, filter, exists, regex, ready]);

  useEffect(() => {
    if (ready === false) {
      return;
    }

    hydrate();
  }, [ready]);

  return { clients: data, pages, loading, page };
};

export type UseCMSBlog = {
  _id: string;
  title?: string;
  metaTitle?: string;
  slug?: string;
  coverImage?: string;
  description?: string;
  metaDescription?: string;
  date?: string;
  updatedAt?: string;
  createdAt?: string;
  author?: string;
  content?: any;
  plainTags?: string[];
  digitalDropTags?: string[];
  licensorTags?: string[];
  scenicProjectionTags?: string[];
  showVariantTags?: string[];
  showBrandTags?: string[];
  choreoGuideTags?: string[];
  solutionTags?: string[];
  projectorTags?: string[];
  writerTags?: string[];
  displayGetStartedForm?: boolean;
};

export const requestBlogsSSR = async ({
  page = 0,
  per = 50,
  regex,
  search,
  filter,
  exists,
}: {
  page?: number;
  per?: number;
  search?: string;
  regex?: number;
  filter?: {
    plainTags?: string[];
    digitalDropTags?: string[];
    licensorTags?: string[] | string;
    scenicProjectionTags?: string[];
    showVariantTags?: string[];
    showBrandTags?: string[];
    choreoGuideTags?: string[];
    solutionTags?: string[];
    projectorTags?: string[];
    storyTypeTags?: string[];
    _id?: string[];
    slug?: string[];
    isFeatured?: boolean;
  };
  exists?: {
    plainTags?: true;
    digitalDropTags?: true;
    licensorTags?: true;
    scenicProjectionTags?: true;
    showVariantTags?: true;
    showBrandTags?: true;
    choreoGuideTags?: true;
    solutionTags?: true;
    projectorTags?: true;
    storyTypeTags?: true;
  };
}) => {
  const params: any = {
    page,
    per,
    search,
    regex,
  };

  if (filter) {
    Object.entries(filter).map(([key, value]) => {
      if (!params.filter) {
        params.filter = {};
      }

      params.filter[key] = Array.isArray(value)
        ? {
            $in: value,
          }
        : value;
    });
  }

  if (exists) {
    Object.entries(exists).map(([key]) => {
      if (!params.filter) {
        params.filter = {};
      }

      params.filter[key] = { $exists: true, $not: { $size: 0 } };
    });
  }

  /**
   * @comment
   * Blogs are always sorted in a descending fashion based on `date` parameter.
   */
  params.sort = {
    date: -1,
  };
  const makeRequestToCMS = useService("cms", {
    cache: 1_800_000,
    timeout: 10_000,
    retry: 3,
  });

  return await makeRequestToCMS("POST", "/api/v1/blog/filter", params);
};

export const useCMSBlogs = (
  {
    page = 0,
    per = 50,
    regex,
    search,
    filter,
    exists,
  }: {
    page?: number;
    per?: number;
    search?: string;
    regex?: number;
    filter?: {
      plainTags?: string[];
      digitalDropTags?: string[];
      licensorTags?: string[] | string;
      scenicProjectionTags?: string[];
      showVariantTags?: string[];
      showBrandTags?: string[];
      choreoGuideTags?: string[];
      solutionTags?: string[];
      projectorTags?: string[];
      storyTypeTags?: string[];
      _id?: string[];
      slug?: string[];
      isFeatured?: boolean;
      isDraft?: boolean;
    };
    exists?: {
      plainTags?: true;
      digitalDropTags?: true;
      licensorTags?: true;
      scenicProjectionTags?: true;
      showVariantTags?: true;
      showBrandTags?: true;
      choreoGuideTags?: true;
      solutionTags?: true;
      projectorTags?: true;
      storyTypeTags?: true;
    };
  },
  ready?: boolean
) => {
  const makeRequestToCMS = useService("cms", {
    cache: 1_800_000,
    timeout: 10_000,
    retry: 3,
  });
  const [data, _data] = useState<UseCMSBlog[]>([]);
  const [pages, _pages] = useState<number>(0);
  const [loading, _loading] = useState<boolean>(true);

  const hydrate = useCallback(() => {
    let current = true;

    if (ready === false) {
      return;
    }

    (async () => {
      const params: any = {
        page,
        per,
        search,
        regex,
      };

      if (filter) {
        Object.entries(filter).map(([key, value]) => {
          if (!params.filter) {
            params.filter = {};
          }

          params.filter[key] = Array.isArray(value)
            ? {
                $in: value,
              }
            : value;
        });
      }

      if (exists) {
        Object.entries(exists).map(([key]) => {
          if (!params.filter) {
            params.filter = {};
          }

          params.filter[key] = { $exists: true, $not: { $size: 0 } };
        });
      }

      /**
       * @comment
       * Blogs are always sorted in a descending fashion based on `date` parameter.
       */
      params.sort = {
        date: -1,
      };

      const request = (await makeRequestToCMS(
        "POST",
        "/api/v1/blog/filter",
        params
      )) as any;

      if (request.data && current) {
        _pages(request.pages);
        _data(request.data);
        _loading(false);
      }
    })();

    return () => {
      current = false;
    };
  }, [page, per, search, filter, exists, regex, ready]);

  useEffect(() => {
    hydrate();
  }, [page, per, search, ready]);

  useEffect(() => {
    hydrate();
  }, [JSON.stringify({ filter, exists, regex })]);

  return { blogs: data, pages, loading, page };
};

export type UseCMSTestimonial = {
  _id?: string;
  title?: string;
  subtitle?: string;
  coverImage?: string;
  profilePhoto?: string;
  description?: string;
  updatedAt?: string;
  createdAt?: string;
  plainTags?: string[];
  digitalDropTags?: string[];
  licensorTags?: string[];
  scenicProjectionTags?: string[];
  showVariantTags?: string[];
  showBrandTags?: string[];
  choreoGuideTags?: string[];
  solutionTags?: string[];
  projectorTags?: string[];
  text?: string;
  location?: string;
  show?: string;
};

export const requestTestimonialsSSR = async ({
  page = 0,
  per = 50,
  regex,
  search,
  filter,
}: {
  page?: number;
  per?: number;
  search?: string;
  regex?: number;
  filter?: {
    plainTags?: string[];
    digitalDropTags?: string[];
    licensorTags?: string[];
    scenicProjectionTags?: string[];
    showVariantTags?: string[];
    showBrandTags?: string[];
    choreoGuideTags?: string[];
    solutionTags?: string[];
    projectorTags?: string[];
  };
  exists?: {
    plainTags?: true;
    digitalDropTags?: true;
    licensorTags?: true;
    scenicProjectionTags?: true;
    showVariantTags?: true;
    showBrandTags?: true;
    choreoGuideTags?: true;
    solutionTags?: true;
    projectorTags?: true;
  };
}) => {
  const makeRequestToCMS = useService("cms", {
    timeout: 10_000,
    retry: 3,
  });

  const params = {
    page,
    per,
    search,
    regex,
    filter,
  };

  return await makeRequestToCMS("POST", "/api/v1/testimonial/filter", params);
};

export const useCMSTestimonials = (
  {
    page = 0,
    per = 50,
    regex,
    search,
    filter,
    exists,
  }: {
    page?: number;
    per?: number;
    search?: string;
    regex?: number;
    filter?: {
      plainTags?: string[];
      digitalDropTags?: string[];
      licensorTags?: string[];
      scenicProjectionTags?: string[];
      showVariantTags?: string[];
      showBrandTags?: string[];
      choreoGuideTags?: string[];
      solutionTags?: string[];
      projectorTags?: string[];
    };
    exists?: {
      plainTags?: true;
      digitalDropTags?: true;
      licensorTags?: true;
      scenicProjectionTags?: true;
      showVariantTags?: true;
      showBrandTags?: true;
      choreoGuideTags?: true;
      solutionTags?: true;
      projectorTags?: true;
    };
  },
  ready?: boolean
) => {
  const makeRequestToCMS = useService("cms", {
    cache: 1_800_000,
    timeout: 10_000,
    retry: 3,
  });
  const [data, _data] = useState<UseCMSTestimonial[]>([]);
  const [pages, _pages] = useState<number>(0);
  const [loading, _loading] = useState<boolean>(true);

  const hydrate = useCallback(() => {
    if (ready === false) {
      return;
    }

    (async () => {
      const params = {
        page,
        per,
        search,
        regex,
        filter,
      };

      const request = (await makeRequestToCMS(
        "POST",
        "/api/v1/testimonial/filter",
        params
      )) as any;

      if (request.data) {
        _data(request.data);
        _pages(request.pages);
        _loading(false);
      }
    })();
  }, [page, per, search, regex, filter, exists, ready]);

  useEffect(() => {
    hydrate();
  }, [page, per, search, ready]);

  useEffect(() => {
    hydrate();
  }, [JSON.stringify({ filter, exists, regex })]);

  return { testimonials: data, pages, loading, page };
};

export type UseCMSProductionPhoto = {
  _id: string;
  image?: string;
  score?: string;
  updatedAt?: string;
  createdAt?: string;
  plainTags?: string[];
  digitalDropTags?: string[];
  licensorTags?: string[];
  scenicProjectionTags?: string[];
  showVariantTags?: string[];
  showBrandTags?: string[];
  choreoGuideTags?: string[];
  solutionTags?: string[];
  projectorTags?: string[];
  name?: string;
  copyrightClaim?: string;
  organizationName?: string;
  productionDate?: string;
};

export const requestCMSProductionPhotoSSR = async ({
  page = 0,
  per = 10,
  regex,
  search,
  filter,
}: {
  page?: number;
  per?: number;
  search?: string;
  regex?: number;
  filter?: {
    plainTags?: string[];
    digitalDropTags?: string[];
    licensorTags?: string[];
    scenicProjectionTags?: string[];
    showVariantTags?: string[];
    showBrandTags?: string[];
    choreoGuideTags?: string[];
    solutionTags?: string[];
    projectorTags?: string[];
  };
}) => {
  const makeRequestToCMS = useService("cms", {
    cache: 1_800_000,
    timeout: 10_000,
    retry: 3,
  });

  const params = {
    page,
    per,
    search,
    regex,
    filter,
  };

  return await makeRequestToCMS("POST", "/api/v1/photo/filter", params);
};

export const useCMSProductionPhoto = (
  {
    page = 0,
    per = 10,
    regex,
    search,
    filter,
  }: {
    page?: number;
    per?: number;
    search?: string;
    regex?: number;
    filter?: {
      plainTags?: string[];
      digitalDropTags?: string[];
      licensorTags?: string[];
      scenicProjectionTags?: string[];
      showVariantTags?: string[];
      showBrandTags?: string[];
      choreoGuideTags?: string[];
      solutionTags?: string[];
      projectorTags?: string[];
    };
  },
  ready?: boolean
) => {
  const makeRequestToCMS = useService("cms", {
    cache: 1_800_000,
    timeout: 10_000,
    retry: 3,
  });
  const [data, _data] = useState<UseCMSProductionPhoto[]>([]);
  const [pages, _pages] = useState<number>(0);
  const [loading, _loading] = useState<boolean>(true);

  const hydrate = useCallback(() => {
    if (ready === false) {
      return;
    }

    (async () => {
      const params = {
        page,
        per,
        search,
        regex,
        filter,
      };

      const request = (await makeRequestToCMS(
        "POST",
        "/api/v1/photo/filter",
        params
      )) as any;

      if (request.data) {
        _data(request.data);
        _pages(request.pages);
        _loading(false);
      }
    })();
  }, [page, per, search, regex, filter, ready]);

  useEffect(() => {
    if (!filter) {
      return;
    }

    if (Object.keys(filter).length === 0) {
      return;
    }

    hydrate();
  }, [JSON.stringify({ filter, regex, ready })]);

  return { images: data, loading, pages, page };
};

export const useCMSRandomProductionPhoto = (
  {
    page = 0,
    per = 10,
    regex,
    search,
    filter,
  }: {
    page?: number;
    per?: number;
    search?: string;
    regex?: number;
    filter?: {
      plainTags?: string[];
      digitalDropTags?: string[];
      licensorTags?: string[];
      scenicProjectionTags?: string[];
      showVariantTags?: string[];
      showBrandTags?: string[];
      choreoGuideTags?: string[];
      solutionTags?: string[];
      projectorTags?: string[];
    };
  },
  ready?: boolean
) => {
  const makeRequestToCMS = useService("cms", {
    cache: 1_800_000,
    timeout: 10_000,
    retry: 3,
  });
  const [data, _data] = useState<UseCMSProductionPhoto[]>([]);
  const [pages, _pages] = useState<number>(0);
  const [loading, _loading] = useState<boolean>(true);

  const hydrate = useCallback(() => {
    if (ready === false) {
      return;
    }

    (async () => {
      const params = {
        page,
        per,
        search,
        regex,
        filter,
      };

      const request = (await makeRequestToCMS(
        "POST",
        "/api/v1/photo/sample",
        params
      )) as any;

      if (request.data) {
        _data(request.data);
        _pages(request.pages);
        _loading(false);
      }
    })();
  }, [page, per, search, regex, filter, ready]);

  useEffect(() => {
    if (!filter) {
      return;
    }

    if (Object.keys(filter).length === 0) {
      return;
    }

    hydrate();
  }, [JSON.stringify({ filter, regex, ready })]);

  return { images: data, loading, pages, page };
};

export type UseCMSDocument = {
  _id: string;
  name?: string;
  slug?: string;
  file?: string;
  description?: string;
  regionTags?: string[];
  updatedAt?: string;
};

export const useCMSDocument = (
  {
    page = 0,
    per = 50,
    regex,
    search,
    filter,
  }: {
    page?: number;
    per?: number;
    search?: string;
    regex?: number;
    filter?: {
      regionTags?: string[];
    };
  },
  ready?: boolean
) => {
  const makeRequestToCMS = useService("cms", {
    cache: 1_800_000,
    timeout: 10_000,
    retry: 3,
  });
  const [data, _data] = useState<UseCMSDocument[] | undefined>(undefined);

  const hydrate = useCallback(() => {
    if (ready === false) {
      return;
    }

    (async () => {
      const params = {
        page,
        per,
        search,
        regex,
        filter,
      };

      const request = (await makeRequestToCMS(
        "POST",
        "/api/v1/document/filter",
        params
      )) as any;

      if (request.data) {
        _data(request.data);
      }
    })();
  }, [page, per, search, regex, filter, ready]);

  useEffect(() => {
    hydrate();
  }, [page, per, ready]);

  return { documents: data || [], loading: !data };
};

export type UseCMSCareer = {
  _id: string;
  title?: string;
  department?: string;
  location?: string;
  position?: string;
  description?: string;
  url?: string;
  content?: any;
};

export const useCMSCareer = () => {
  const makeRequestToCMS = useService("cms", {
    cache: 60_000,
    timeout: 10_000,
    retry: 3,
  });
  const [data, _data] = useState<UseCMSCareer[] | undefined>(undefined);

  const hydrate = useCallback(() => {
    (async () => {
      const request = (await makeRequestToCMS("GET", "/api/v1/career")) as any;

      if (request.data) {
        _data(request.data);
      }
    })();
  }, []);

  useEffect(() => {
    hydrate();
  }, []);

  return { careers: data || [], loading: !data };
};

export type UseCMSHomepage = {
  partners: UseCMSPartner[];
  testimonials: UseCMSTestimonial[];
  featureds: UseCMSFeatured[];
  blogs: UseCMSBlog[];
  slides: UseCMSSlide[];
};

export const requestCMSHomepageSSR = async (
  region: string | number
): Promise<UseCMSHomepage> => {
  const timeNoW = new Date().getTime();
  const makeRequestToCMS = useService("cms", {
    revalidate: 1_800_000,
    timeout: 10_000,
    retry: 3,
  });

  const request = (await makeRequestToCMS(
    "GET",
    "/api/v1/collection/homepage",
    {
      region,
    }
  )) as any;

  const timeTook = new Date().getTime() - timeNoW;

  const timeTookInMs = timeTook % 1000;

  return request;
};

export const useCMSHomepage = ({ region }: { region?: string | number }) => {
  const [data, _data] = useState<UseCMSHomepage | undefined>(undefined);

  useEffect(() => {
    if (!region) {
      return;
    }

    let current = true;

    const makeRequestToCMS = useService("cms", {
      cache: 60_000,
      timeout: 10_000,
      retry: 3,
    });

    (async () => {
      const request = await makeRequestToCMS(
        "GET",
        "/api/v1/collection/homepage",
        {
          region,
        }
      );

      if (request && current) {
        _data(request as UseCMSHomepage);
      }
    })();

    return () => {
      current = false;
    };
  }, [region]);

  return { data, loading: !data };
};

export type UseCMSVideo = {
  _id: string;
  title?: string;
  videoURL?: string;
  videoTypeTags?: string[];
};

export const useCMSVideo = () => {
  const makeRequestToCMS = useService("cms", {
    cache: 1_800_000,
    timeout: 10_000,
    retry: 3,
  });
  const [data, _data] = useState<UseCMSVideo[] | undefined>(undefined);

  const hydrate = useCallback(() => {
    (async () => {
      const request = (await makeRequestToCMS("GET", "/api/v1/video")) as any;

      if (request.data) {
        _data(request.data);
      }
    })();
  }, []);

  useEffect(() => {
    hydrate();
  }, []);

  return { videos: data || [], loading: !data };
};

export type UseCMSRelease = {
  _id: string;
  title?: string;
  content?: any;
};

export const useCMSRelease = () => {
  const makeRequestToCMS = useService("cms", {
    cache: 1_800_000,
    timeout: 10_000,
    retry: 3,
  });
  const [data, _data] = useState<UseCMSRelease[] | undefined>(undefined);

  const hydrate = useCallback(() => {
    (async () => {
      const request = (await makeRequestToCMS("GET", "/api/v1/release")) as any;

      if (request.data) {
        _data(request.data);
      }
    })();
  }, []);

  useEffect(() => {
    hydrate();
  }, []);

  return { releases: data || [], loading: !data };
};

export type UseCMSFAQ = {
  _id: string;
  title?: string;
  content?: any;
};

export const useCMSFAQ = () => {
  const makeRequestToCMS = useService("cms", {
    cache: 1_800_000,
    timeout: 10_000,
    retry: 3,
  });
  const [data, _data] = useState<UseCMSFAQ[] | undefined>(undefined);

  const hydrate = useCallback(() => {
    (async () => {
      const request = (await makeRequestToCMS("GET", "/api/v1/faq")) as any;

      if (request.data) {
        _data(request.data);
      }
    })();
  }, []);

  useEffect(() => {
    hydrate();
  }, []);

  return { faq: data || [], loading: !data };
};

export type UseCMSGatedContentPage = {
  _id: string;
  title?: string;
  description?: string;
  slug?: string;
  url?: string;
  sections?: {
    title?: string;
    subtitle?: string;
    description?: string;
    image?: string;
  }[];
  file?: string;
  coverImage?: string;
  rectangularHeroThumbnail?: string;
};

export const useCMSGatedContentPage = (
  {
    page = 0,
    per = 50,
    regex,
    search,
    filter,
  }: {
    page?: number;
    per?: number;
    search?: string;
    regex?: number;
    filter?: {
      _id?: string;
      slug?: string;
    };
  },
  ready?: boolean
) => {
  const makeRequestToCMS = useService("cms", {
    cache: 1_800_000,
    timeout: 10_000,
    retry: 3,
  });
  const [data, _data] = useState<UseCMSGatedContentPage[] | undefined>(
    undefined
  );

  const hydrate = useCallback(() => {
    if (ready === false) {
      return;
    }
    (async () => {
      const params = {
        page,
        per,
        search,
        regex,
        filter,
      };

      const request = (await makeRequestToCMS(
        "POST",
        "/api/v1/gated-content-page/filter",
        {
          ...params,
        }
      )) as any;

      if (request.data) {
        _data(request.data);
      }
    })();
  }, [ready]);

  useEffect(() => {
    hydrate();
  }, [ready]);

  return { pages: data || [], loading: !data };
};

export type UseCMSGatedContentPromotion = {
  _id: string;
  title?: string;
  active?: boolean;
  description?: string;
  button?: string;
  featureImage?: string;
  customURL?: string;
  gatedContentPage?: string;
  regionTags?: string[];
  pageTags?: string[];
  backgroundColor?: string;
  textColor?: string;
  buttonBackgroundColor?: string;
  buttonTextColor?: string;
};

export const useCMSGatedContentPromotion = (
  {
    page = 0,
    per = 50,
    regex,
    search,
    filter,
  }: {
    page?: number;
    per?: number;
    search?: string;
    regex?: number;
    filter?: {
      regionTags?: string;
      pageTags?: string;
    };
  },
  ready?: boolean
) => {
  const makeRequestToCMS = useService("cms", {
    cache: 1_800_000,
    timeout: 10_000,
    retry: 3,
  });

  const [data, _data] = useState<UseCMSGatedContentPromotion[] | undefined>(
    undefined
  );

  const hydrate = useCallback(() => {
    if (ready === false) {
      return;
    }
    (async () => {
      const params = {
        page,
        per,
        search,
        regex,
        filter,
      };

      const request = (await makeRequestToCMS(
        "POST",
        "/api/v1/gated-content-promotion/filter",
        {
          ...params,
        }
      )) as any;

      if (request.data) {
        _data(request.data);
      }
    })();
  }, [ready]);

  useEffect(() => {
    hydrate();
  }, [ready]);

  return { promotions: data || [], loading: !data };
};

export const useCMSCollectionBlog = () => {
  const [data, _data] = useState<
    | {
        featureds: UseCMSBlog[];
        blogs: UseCMSBlog[];
        groups: UseCMSGroup[];
      }
    | undefined
  >(undefined);

  useEffect(() => {
    let current = true;

    const makeRequestToCMS = useService("cms", {
      cache: 1_800_000,
      timeout: 10_000,
      retry: 3,
    });

    (async () => {
      const request = await makeRequestToCMS("GET", "/api/v1/collection/blog");

      if (request && current) {
        _data(
          request as {
            featureds: UseCMSBlog[];
            blogs: UseCMSBlog[];
            groups: UseCMSGroup[];
          }
        );
      }
    })();

    return () => {
      current = false;
    };
  }, []);

  return { data, loading: !data };
};

export const useSignedURL = ({ id }: { id?: string }, ready: boolean) => {
  const [data, _data] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!ready) {
      return;
    }

    let current = true;

    (async () => {
      const makeRequestToCMS = useService("cms", {
        cache: 1_800_000,
        timeout: 10_000,
        retry: 3,
      });

      const request = (await makeRequestToCMS(
        "GET",
        `/api/v1/file/${id}/signed`
      )) as {
        signed: string;
        success: boolean;
      };

      if (request?.success && current) {
        _data(request?.signed as string);
      }
    })();

    return () => {
      current = false;
    };
  }, [id, ready]);

  return { url: data, loading: !data };
};
