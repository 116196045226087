import React from "react";
import { Box } from "simple-effing-primitive-layout";

import { Act, Scene, ShowData, VideoSet } from "../types";
import ActMoverWrapper from "./bases/ActMoverWrapper";
import CueMoverWrapper from "./bases/CueMoverWrapper";
import EditorActWrapper from "./bases/EditorActWrapper";
import EditorCard from "./bases/EditorCard";
import EditorCueWrapper, {
  EditorCueWrapperProps,
} from "./bases/EditorCueWrapper";
import EditorSceneWrapper from "./bases/EditorSceneWrapper";
import SceneMoverWrapper from "./bases/SceneMoverWrapper";
import { Colors } from "./shared";

export interface EditorParams {
  data: ShowData;
  onActMove: (oldActIndex: number, newActIndex: number) => void;
  onSceneMove: (
    actIndex: number,
    oldSceneIndex: number,
    newSceneIndex: number
  ) => void;
  onCueMove: (
    actIndex: number,
    sceneIndex: number,
    oldCueIndex: number,
    newCueIndex: number
  ) => void;
  onCueTeleport?: (
    teleportMode: string,
    oldActIndex: number,
    oldSceneIndex: number,
    oldCueIndex: number,
    newActIndex: number,
    newSceneIndex: number,
    newCueIndex: number
  ) => void;
  onCueUpdate?: (
    actIndex: number,
    sceneIndex: number,
    cueIndex: number,
    cueData: VideoSet
  ) => void;
  onDangerousDataUpdate?: (data: ShowData) => void;
  onActDuplicate: (actIndex: number) => void;
  onSceneDuplicate: (actIndex: number, sceneIndex: number) => void;
  onCueDuplicate: (
    actIndex: number,
    sceneIndex: number,
    cueIndex: number
  ) => void;
  onActDisable: (actIndex: number) => void;
  onSceneDisable: (actIndex: number, sceneIndex: number) => void;
  onCueDisable: (
    actIndex: number,
    sceneIndex: number,
    cueIndex: number
  ) => void;
  onActEnable: (actIndex: number) => void;
  onSceneEnable: (actIndex: number, sceneIndex: number) => void;
  onCueEnable: (actIndex: number, sceneIndex: number, cueIndex: number) => void;
  renderActWrapper: (props?: unknown) => React.ReactNode;
  renderSceneWrapper: (props?: unknown) => React.ReactNode;
  renderCueWrapper: (props: EditorCueWrapperProps) => React.ReactNode;
  config?: unknown;
}

const Editor = ({
  data,
  onActMove,
  onSceneMove,
  onCueMove,
  onCueTeleport,
  onActDuplicate,
  onSceneDuplicate,
  onCueDuplicate,
  onActDisable,
  onSceneDisable,
  onCueDisable,
  onActEnable,
  onSceneEnable,
  onCueEnable,
  onCueUpdate,
  renderActWrapper,
  renderSceneWrapper,
  renderCueWrapper,
  onDangerousDataUpdate,
}: EditorParams) => {
  return (
    <Box
      css="stage-player-editor-main-wrapper"
      color={Colors.foreground}
      parse="br:10 oa:hidden"
    >
      <ActMoverWrapper
        mutate={onActMove}
        render={(onActMoveCallback) =>
          data.acts.map((act: Act, a: number) => (
            <EditorCard
              index={a}
              id={act.sortIdentifier || act.act_id}
              move={onActMoveCallback}
              type="act"
              key={act.sortIdentifier || act.act_id}
            >
              <EditorActWrapper
                act={act}
                data={data}
                actIndex={a}
                onActMove={onActMove}
                onActDuplicate={onActDuplicate}
                onActDisable={onActDisable}
                onActEnable={onActEnable}
                render={renderActWrapper}
                enabled={!act.hidden}
                onDangerousDataUpdate={onDangerousDataUpdate}
              >
                <SceneMoverWrapper
                  mutate={onSceneMove}
                  act={a}
                  render={(onSceneMoveCallback) =>
                    act.scenes.map((scene: Scene, s: number) => (
                      <EditorCard
                        type={"scene | " + (act.sortIdentifier || act.act_id)}
                        index={s}
                        key={scene.sortIdentifier || scene.scene_id}
                        id={scene.sortIdentifier || scene.scene_id}
                        move={onSceneMoveCallback}
                      >
                        <EditorSceneWrapper
                          scene={scene}
                          data={data}
                          actIndex={a}
                          sceneIndex={s}
                          onSceneMove={onSceneMove}
                          onSceneDuplicate={onSceneDuplicate}
                          onSceneDisable={onSceneDisable}
                          onSceneEnable={onSceneEnable}
                          render={renderSceneWrapper}
                          enabled={!scene.disabled}
                          actEnabled={!act.hidden}
                          onDangerousDataUpdate={onDangerousDataUpdate}
                        >
                          <CueMoverWrapper
                            mutate={onCueMove}
                            act={a}
                            scene={s}
                            render={(onCueMoveCallback) =>
                              scene.sets.map((cue: VideoSet, c: number) => (
                                <EditorCard
                                  type={
                                    "cue | " +
                                    (scene.sortIdentifier || scene.scene_id)
                                  }
                                  key={cue.identifier}
                                  id={cue.identifier}
                                  index={c}
                                  move={onCueMoveCallback}
                                  render={() => (
                                    <EditorCueWrapper
                                      data={data}
                                      cue={cue}
                                      actIndex={a}
                                      sceneIndex={s}
                                      cueIndex={c}
                                      onCueTeleport={onCueTeleport}
                                      onCueMove={onCueMove}
                                      onCueDuplicate={onCueDuplicate}
                                      onCueDisable={onCueDisable}
                                      onCueEnable={onCueEnable}
                                      onCueUpdate={onCueUpdate}
                                      render={renderCueWrapper}
                                      enabled={cue.enabled}
                                      sceneEnabled={!scene.disabled}
                                      actEnabled={!act.hidden}
                                      cueCount={scene.sets.length}
                                      sceneCount={act.scenes.length}
                                      actCount={data.acts.length}
                                    />
                                  )}
                                />
                              ))
                            }
                          />
                        </EditorSceneWrapper>
                      </EditorCard>
                    ))
                  }
                />
              </EditorActWrapper>
            </EditorCard>
          ))
        }
      />
    </Box>
  );
};

export default Editor;
