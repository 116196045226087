import { DesignedIcon, Rule } from "@with-nx/simple-ui/atoms";
import { Col, Row } from "antd";
import { Calendar } from "../calendar/calendar";
import { Box } from "simple-effing-primitive-layout";
import { FC, useEffect, useState } from "react";
import CalendarUtils from "../calendar/CalendarUtils";
import { TimeZoneSelector } from "./timezone-selector";
import { TimeSelector } from "./time-selector";
import { useService } from "@with-nx/hooks-n-helpers";
import moment from "moment-timezone";

const getDatesForAvailabilityCheck = (dates: (string | undefined)[]) => {
  const daysInterval = 6;

  return dates.reduce<{ start?: string; end?: string }[]>(
    (dateRanges, date, index, dates) => {
      if (index % daysInterval === 0) {
        dateRanges.push({
          start: date,
          end: dates[Math.min(index + daysInterval - 1, dates.length - 1)],
        });
      }

      return dateRanges;
    },
    []
  );
};

const getCalendlyAvailableTimes = async (start?: string, end?: string) => {
  if (!start || !end) {
    return [];
  }

  try {
    const origin = window.location.origin;

    const makeRequestToSelf = useService("custom", {
      url: `${origin}`,
      cache: 2 * 60 * 1000,
    });

    const response = await makeRequestToSelf("GET", `/api/calendly`, {
      start,
      end,
    });

    return response;
  } catch (error) {
    console.error("Error fetching available times:", error);
    return [];
  }
};

interface MeetingSchedulerProps {
  date: string;
  time: string;
  timeZone: string;
  _date: any;
  _time: any;
  _timeZone: any;
  large?: boolean;
}

export const MeetingScheduler: FC<MeetingSchedulerProps> = ({
  date,
  time,
  timeZone,
  _timeZone,
  _date,
  _time,
  large,
}) => {
  const [times, _times] = useState<any[]>([]);
  const [selectedTimes, _selectedTimes] = useState<string[]>([]);
  const today = CalendarUtils.today();
  const [calendar, _calendar] = useState(CalendarUtils.calendar(today));

  let dates = calendar
    .flatMap((days) => days.map((day) => day?.date))
    .filter((day) => !!day);

  const todayIndex = dates.indexOf(today);
  dates = dates.slice(todayIndex + 1);

  const datesToCheck = getDatesForAvailabilityCheck(dates);
  const [loadingAvailability, _loadingAvailability] = useState(true);
  const [unavailableDates, _unavailableDates] = useState<
    (string | undefined)[]
  >([]);

  const reset = () => {
    _date("");
    _times([]);
    _selectedTimes([]);
    _time("");
  };

  useEffect(() => {
    (async () => {
      _loadingAvailability(true);
      const requests = datesToCheck.map(({ start, end }) =>
        getCalendlyAvailableTimes(start, end)
      );

      const results = await Promise.all(requests);
      const availableTimes = results.flat();

      const unavailableDates = dates.filter((date) => {
        const exists = availableTimes.find((time) =>
          moment
            .tz(time, timeZone)
            .format("YYYY-MM-DD HH:mm")
            ?.startsWith(date!)
        );

        return !exists;
      });

      _unavailableDates(unavailableDates);
      _times(availableTimes);

      _loadingAvailability(false);
    })();
  }, [JSON.stringify(datesToCheck), timeZone]);

  return (
    <Row gutter={[30, 20]}>
      <Col xs={24} xl={large ? 16 : 12}>
        <Rule parse="!_h2 d:block mb:24 c:var(--black-base)">
          Meet with a member of our team!
        </Rule>
        <Rule parse="!_bxl d:flex a:center mb:24 c:var(--grey-80)">
          <Box right={10}>
            <DesignedIcon name="clock" size={24} />
          </Box>
          30 min
        </Rule>

        <Rule parse="!_bxl d:block mb:24 c:var(--grey-80)">
          Web conferencing details provided upon confirmation.
        </Rule>

        <Rule parse="d:block c:var(--grey-60)">
          We would love for you to join us in a Google Meet where we can address
          your questions about our resources. Can't find a time that works with
          your schedule? Feel free to email us at{" "}
          <Rule parse="c:var(--black-base)">
            <a
              style={{
                textDecoration: "underline",
              }}
              href="mailto:hello@broadwaymedia.com"
            >
              hello@broadwaymedia.com
            </a>
          </Rule>{" "}
          so we can come up with a solution.
        </Rule>
      </Col>

      <Col xs={24} xl={large ? 8 : 12}>
        <Rule parse="!_h3 d:block ta:center c:var(--black-base) mb:20">
          Select Date
        </Rule>

        <Box parse="d:flex j:center w:100% p:relative">
          <Calendar
            theme="light"
            value={[date]}
            unavailable={unavailableDates}
            change={(values) => {
              const newDate = values[0];

              if (newDate === date) {
                _selectedTimes([]);
                _time("");
                _date("");
              } else {
                _selectedTimes(
                  times
                    .filter(
                      (time) =>
                        moment.tz(time, timeZone).format("YYYY-MM-DD") ===
                        newDate
                    )
                    .sort((a, b) => a.localeCompare(b))
                );
                _date(newDate);
              }
            }}
            later
            loading={loadingAvailability}
            onCalendarChange={(calendar) => {
              _calendar(calendar);
              reset();
            }}
          />
        </Box>

        <TimeZoneSelector
          timeZone={timeZone}
          _timeZone={(timeZone: string) => {
            _timeZone(timeZone);

            _selectedTimes(
              times
                .filter(
                  (time) =>
                    moment.tz(time, timeZone).format("YYYY-MM-DD") === date
                )
                .sort((a, b) => a.localeCompare(b))
            );
          }}
        />
        <TimeSelector
          timeZone={timeZone}
          time={time}
          _time={_time}
          selectedTimes={selectedTimes}
          date={date}
        />
      </Col>
    </Row>
  );
};
