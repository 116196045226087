import { MutableRefObject, RefObject, useEffect, useState } from "react";
import { boolean } from "yup";

export const useVisible = (
  reference: RefObject<HTMLDivElement> | MutableRefObject<HTMLDivElement>,
  disconnect?: boolean,
  continious?: boolean
) => {
  const [visible, _visible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        _visible(true);
        if (disconnect) {
          observer.disconnect();
        }
      } else {
        if (continious) {
          _visible(false);
        }
      }
    });

    if (!reference?.current) {
      return;
    }

    observer.observe(reference.current);

    return () => {
      observer.disconnect();
    };
  }, [reference]);

  return { visible };
};

export default useVisible;
