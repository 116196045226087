import {
  staticProjectorAccessoriesData,
  staticProjectorRentalData,
} from "@with-nx/hooks-n-helpers";
import moment from "moment";

import { Ecommerce } from "../types";

const NanNumber = (item: unknown) => {
  return isNaN(item as number) ? 0 : Number(item);
};
export class ShoppingCartHelpers {
  private static getCurrentOrigin = () => {
    if (typeof window !== "undefined") {
      return window.location.origin;
    }
    return "";
  };

  static convertProductTypeToName = (
    product: Ecommerce.Schemas.ProductSchemaType
  ) => {
    switch (product.type) {
      case "AD_HOC":
        return "";
      case "CHOREO_GUIDE":
        return "Choreography Guide";
      case "DIGITAL_DROP":
        return "Digital Backdrops";
      case "SCENIC_PROJECTION_PACKAGE":
        return "Scenic Projection";
      case "STAGE_GEAR":
        return "StageGear";
      case "ACCESSORY":
        return "Accessory";
      default:
        return "";
    }
  };

  static getProductTitle = (product: Ecommerce.Schemas.ProductSchemaType) => {
    switch (product.type) {
      case "AD_HOC":
        return product.adHocName;
      case "CHOREO_GUIDE":
        return product.choreoGuide?.show?.title || "Unknown";
      case "DIGITAL_DROP":
        return product.digitalDrop?.name || "Unknown";
      case "SCENIC_PROJECTION_PACKAGE":
        return product.scenicProjection?.show?.title || "Unknown";
      case "PROJECTOR":
        return (
          staticProjectorRentalData.find((i) => {
            return i.variation === product.variation;
          })?.name || "Unknown"
        );
      case "ACCESSORY":
        return (
          staticProjectorAccessoriesData.find((i) => {
            return i.variation === product.variation;
          })?.name || "Unknown"
        );
      case "STAGE_GEAR":
        return product?.gearResource?.name || "Unknown";
      default:
        return "Unknown";
    }
  };

  private static getProductSlug = (
    item:
      | Ecommerce.Schemas.LicensedItemSchemaType
      | Ecommerce.Schemas.NonLicensedItemSchemaType
  ) => {
    switch (item.product.type) {
      case "CHOREO_GUIDE":
        return item.product.choreoGuide?.show?.slug || undefined;
      case "SCENIC_PROJECTION_PACKAGE":
        return item.product.scenicProjection?.show?.slug || undefined;
      default:
        return undefined;
    }
  };

  static getProductURL = (
    item:
      | Ecommerce.Schemas.LicensedItemSchemaType
      | Ecommerce.Schemas.NonLicensedItemSchemaType
  ) => {
    const origin = this.getCurrentOrigin();
    const slug = this.getProductSlug(item);
    const title = this.getProductTitle(item.product);

    switch (item.product.type) {
      case "CHOREO_GUIDE":
        return slug
          ? `${origin}/shows/${slug}/${slug}/choreography-guides`
          : `${origin}/search?search=${encodeURIComponent(title!)}`;
      case "DIGITAL_DROP":
        return `${origin}/digital-backdrops?search=${encodeURIComponent(
          title!
        )}`;
      case "SCENIC_PROJECTION_PACKAGE":
        return slug
          ? `${origin}/shows/${slug}/${slug}/scenic-projections`
          : `${origin}/search?search=${encodeURIComponent(title!)}`;
      case "PROJECTOR":
        return `${origin}/projectors`;
      case "ACCESSORY":
        return `${origin}/projectors`;
      default:
        return origin;
    }
  };

  static canProgress = (cart: Ecommerce.Cart.State) => {
    if (cart.step === 0) {
      const allValid = cart.data?.productions?.every((production) => {
        return this.productionDetailsValid(
          "non-licensed",
          production as Ecommerce.Schemas.ProductionSchemaType
        );
      });

      if (!allValid) {
        return;
      }

      return NanNumber(cart?.data?.productions?.length) > 0;
    }

    if (cart.step === 1) {
      const licensedItemsValidity = cart.data?.licensedItems?.map((item) => {
        const production = item.production;

        if (production) {
          if (Number(production.licensorCode?.length) < 3) {
            return false;
          }
        }

        if (
          production?.firstPerformance?.length &&
          production?.lastPerformance?.length &&
          production?.organizationName?.length &&
          production?.licensorCode?.length &&
          production?.showTitle?.length
        ) {
          if (
            moment(production?.firstPerformance).isAfter(moment()) &&
            !moment(production?.lastPerformance).isBefore(
              moment(production?.firstPerformance)
            ) &&
            production?.organizationName.length >= 3 &&
            production?.showTitle.length >= 3 &&
            production?.licensorCode.length >= 3
          ) {
            return true;
          }
        }

        return false;
      });

      const nonLicensedItemsValidity = cart.data?.nonLicensedItems?.map(
        (item) => {
          const production = item.production;

          if (production) {
            if (Number(production.licensorCode?.length) < 3) {
              return false;
            }
          }

          if (
            production?.organizationName?.length &&
            production?.showTitle?.length &&
            item.product.type === "PROJECTOR"
          ) {
            if (
              production?.organizationName.length >= 3 &&
              production?.showTitle.length >= 3
            ) {
              return true;
            }
          }

          if (
            production?.firstPerformance?.length &&
            production?.lastPerformance?.length &&
            production?.organizationName?.length &&
            production?.showTitle?.length &&
            item.product.type !== "PROJECTOR"
          ) {
            if (
              moment(production?.firstPerformance).isAfter(moment()) &&
              !moment(production?.lastPerformance).isBefore(
                moment(production?.firstPerformance)
              ) &&
              production?.organizationName.length >= 3 &&
              production?.showTitle.length >= 3
            ) {
              return true;
            }
          }

          return false;
        }
      );

      const stageGearValidity = cart.data?.nonLicensedItems?.map((item) => {
        if (item?.product?.type === "STAGE_GEAR") {
          const today = moment();
          const daysUntil = moment(item?.rentalStart).diff(today, "days");

          if (daysUntil < 7) {
            return false;
          }
        }

        return true;
      });

      if (
        licensedItemsValidity?.every((item) => item === true) &&
        nonLicensedItemsValidity?.every((item) => item === true) &&
        stageGearValidity?.every((item) => item === true)
      ) {
        return true;
      }
      return false;
    }

    if (cart.step === 2) {
      return !this.paymentError(cart);
    }

    return false;
  };

  static paymentError = (cart: Ecommerce.Cart.State) => {
    const bvalid = (() => {
      const address = cart.addresses?.billing;

      if (!address) {
        return false;
      }

      if (
        NanNumber(address.line1?.length) < 1 ||
        NanNumber(address.postalCode?.length) < 1 ||
        NanNumber(address.location?.country?.code?.length) < 1 ||
        NanNumber(address.location?.country?.name?.length) < 1 ||
        NanNumber(address.location?.state?.code?.length) < 1 ||
        NanNumber(address.location?.state?.countryCode?.length) < 1 ||
        NanNumber(address.location?.state?.name?.length) < 1 ||
        NanNumber(address.location?.city?.name?.length) < 1 ||
        NanNumber(String(address.location?.city?.id)?.length) < 1 ||
        !address.line1?.length ||
        !address.postalCode?.length ||
        !address.location?.country?.code?.length ||
        !address.location?.country?.name?.length ||
        !address.location?.state?.code?.length ||
        !address.location?.state?.countryCode?.length ||
        !address.location?.state?.name?.length ||
        !address.location?.city?.name?.length ||
        !address.location?.city?.id
      ) {
        return false;
      }

      return true;
    })();

    const svalid = (() => {
      const address = cart.addresses?.shipping;

      if (!address) {
        return false;
      }

      if (
        NanNumber(address.line1?.length) < 1 ||
        NanNumber(address.postalCode?.length) < 1 ||
        NanNumber(address.location?.country?.code?.length) < 1 ||
        NanNumber(address.location?.country?.name?.length) < 1 ||
        NanNumber(address.location?.state?.code?.length) < 1 ||
        NanNumber(address.location?.state?.countryCode?.length) < 1 ||
        NanNumber(address.location?.state?.name?.length) < 1 ||
        NanNumber(address.location?.city?.name?.length) < 1 ||
        NanNumber(String(address.location?.city?.id)?.length) < 1 ||
        !address.line1?.length ||
        !address.postalCode?.length ||
        !address.location?.country?.code?.length ||
        !address.location?.country?.name?.length ||
        !address.location?.state?.code?.length ||
        !address.location?.state?.countryCode?.length ||
        !address.location?.state?.name?.length ||
        !address.location?.city?.name?.length ||
        !address.location?.city?.id
      ) {
        return false;
      }

      return true;
    })();

    const hasStageGear = !!cart.data?.nonLicensedItems?.find(
      (item) => item.product.type === "STAGE_GEAR"
    );

    if (hasStageGear) {
      if (
        !bvalid ||
        (!cart?.control?.same && !svalid && !cart?.control?.correct)
      ) {
        return "Please fill out all required fields.";
      }

      const address = cart?.control?.same
        ? cart?.addresses?.billing
        : cart?.addresses?.shipping;

      const country = address?.location?.country?.code;
      const isValidCountry = country
        ? cart?.stageGear?.shippingCountries?.includes(country)
        : false;

      if (!isValidCountry) {
        return "StageGear products are only available for customers who are in United States region.";
      }
    } else {
      return !bvalid ? "Please fill out all required fields." : "";
    }

    return "";
  };

  static canPay = (cart: Ecommerce.Cart.State) => {
    return this.canProgress(cart) && cart.step === 2;
  };

  static cantPayReason = (cart: Ecommerce.Cart.State) => {
    return cart.step === 2 && !this.canProgress(cart)
      ? this.paymentError(cart)
      : undefined;
  };

  static productionDetailsValid = (
    type: "non-licensed" | "licensed" | "stage_gear",
    production: Ecommerce.Schemas.ProductionSchemaType
  ) => {
    if (type === "licensed") {
      if (
        production?.firstPerformance?.length &&
        production?.lastPerformance?.length &&
        production?.organizationName?.length &&
        production?.showTitle?.length &&
        production?.licensorCode?.length
      ) {
        if (
          moment(production?.firstPerformance).isAfter(moment()) &&
          !moment(production?.lastPerformance).isBefore(
            moment(production?.firstPerformance)
          ) &&
          production?.organizationName.length >= 3 &&
          production?.showTitle.length >= 3 &&
          production?.licensorCode.length >= 3
        ) {
          return true;
        }
      }
      return false;
    } else if (type === "stage_gear") {
      if (
        production?.organizationName?.length &&
        production?.showTitle?.length
      ) {
        if (
          production?.organizationName.length >= 3 &&
          production?.showTitle.length >= 3
        ) {
          return true;
        }
        return false;
      }
    } else {
      if (
        production?.firstPerformance?.length &&
        production?.lastPerformance?.length &&
        production?.organizationName?.length &&
        production?.showTitle?.length
      ) {
        if (
          moment(production?.firstPerformance).isAfter(moment()) &&
          !moment(production?.lastPerformance).isBefore(
            moment(production?.firstPerformance)
          ) &&
          production?.organizationName.length >= 3 &&
          production?.showTitle.length >= 3
        ) {
          return true;
        }
      }
    }

    return false;
  };

  static productionDetailsError = (
    type: "non-licensed" | "licensed" | "stage_gear",
    production?: Ecommerce.Schemas.ProductionSchemaType
  ) => {
    if (!production) {
      return false;
    }

    if (type === "licensed") {
      if (NanNumber(production?.licensorCode?.length) < 3) {
        return "Your licensor code must be longer than 3 characters. Please go back and update your production details.";
      }
    }

    if (type === "licensed" || type === "non-licensed") {
      if (!production?.firstPerformance?.length) {
        return "Your first performance date is required. Please go back and update your production details.";
      }

      if (!production?.lastPerformance?.length) {
        return "Your last performance date is required. Please go back and update your production details.";
      }

      if (NanNumber(production?.organizationName?.length) < 3) {
        return "Your organization name must be longer than 3 characters. Please go back and update your production details.";
      }

      if (NanNumber(production?.showTitle?.length) < 3) {
        return "Your show title must be longer than 3 characters. Please go back and update your production details.";
      }

      if (moment(production?.firstPerformance).isBefore(moment())) {
        return "Your first performance date must be in the future. Please go back and update your production details.";
      }

      if (moment(production?.lastPerformance).isBefore(moment())) {
        return "Your last performance date must be in the future. Please go back and update your production details.";
      }

      if (
        moment(production?.firstPerformance).isAfter(
          moment(production?.lastPerformance)
        )
      ) {
        return "Your first performance date must be before or equal to your last performance date. Please go back and update your production details.";
      }
    }

    if (type === "stage_gear") {
      if (NanNumber(production?.organizationName?.length) < 3) {
        return "Your organization name must be longer than 3 characters. Please go back and update your production details.";
      }

      if (NanNumber(production?.showTitle?.length) < 3) {
        return "Your show title must be longer than 3 characters. Please go back and update your production details.";
      }
    }

    return false;
  };
}

export default ShoppingCartHelpers;
