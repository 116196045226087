import { useEffect, useMemo, useState } from "react";

import { Fuzzy } from "./Fuzzy";

type SitemapEntry = {
  priority: number;
  name: string;
  slug: string;
  hide?: boolean;
  similarity?: number;
};

export const useSitemap = (s?: string) => {
  const [sitemap, _sitemap] = useState<SitemapEntry[]>([]);

  useEffect(() => {
    (() => {
      fetch(`/json/sitemap.json`)
        .then((response) => response.json())
        .then((json) => {
          const _json: SitemapEntry[] = json;
          _sitemap(_json.filter((entry) => entry.hide !== true));
        });
    })();
  }, []);

  const entries = useMemo(() => {
    if (!s || s?.length === 0) {
      return sitemap;
    }

    const similarities: SitemapEntry[] = [];

    sitemap.forEach((entry) => {
      if (entry.name === s || entry.name.includes(s)) {
        return similarities.push({ ...entry, similarity: 1 });
      }

      if (Fuzzy.simplify(entry.name)?.startsWith(Fuzzy.simplify(s))) {
        return similarities.push({ ...entry, similarity: 0.9 });
      }

      if (
        Fuzzy.slugify(entry.name) === Fuzzy.slugify(s) ||
        Fuzzy.slugify(entry.name).includes(Fuzzy.slugify(s))
      ) {
        return similarities.push({ ...entry, similarity: 0.8 });
      }

      const similarity = Fuzzy.similarity(s, entry.name);

      if (similarity > 0.4) {
        return similarities.push({ ...entry, similarity });
      }

      return false;
    });

    return similarities.sort((a, b) => {
      if (a.similarity === b.similarity) {
        return a.priority - b.priority;
      }
      return (b?.similarity || 0) - (a?.similarity || 0);
    });
  }, [s, sitemap]);

  return entries || [];
};

export default useSitemap;
