import { useEffect, useState } from "react";

import { StageGearProduct } from "./stage-gear/types/stage-gear";
import useService from "./useService";

interface StageGearCategory {
  id: string;
  name: string;
  slug: string;
  attachments?: {
    name: string;
    blob: {
      signedUrl: string;
    };
  }[];
}

export const useStageGearCategories = (slug?: string) => {
  const [categories, _categories] = useState<StageGearCategory[]>([]);
  const [loading, _loading] = useState<boolean>(true);
  const [error] = useState<string | undefined>(undefined);

  const service = useService("microservice");

  const fetchCategories = async (slug?: string) => {
    _loading(true);

    try {
      const params = {
        "pagination[page]": 1,
        "pagination[pageSize]": 10,
        "pagination[orderBy][0][slug]": "ASC",
        "includes[attachments]": "true",
        ...(slug ? { "filter[slugs][0]": slug } : {}),
      };

      const response = await service(
        "GET",
        `/backstage/stage-gear/categories`,
        params
      );

      if (response?.["result"]) {
        const result = response?.["result"];

        const index = result.findIndex(
          (category: { slug: string }) => category.slug === "accessories"
        );
        const accessories = result.splice(index, 1);
        _categories([...result, ...accessories]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      _loading(false);
    }
  };

  useEffect(() => {
    fetchCategories(slug);
  }, [slug]);

  return {
    categories,
    loading: (!error && !categories) || loading,
    error,
  };
};

export const useStageGearProducts = (
  categorySlug?: string,
  productSlug?: string
) => {
  const [products, _products] = useState<StageGearProduct[]>([]);
  const [loading, _loading] = useState<boolean>(true);
  const [checking, _checking] = useState<boolean>(false);
  const [error] = useState<string | undefined>(undefined);

  const service = useService("microservice");

  const fetchProducts = async (categorySlug?: string, productSlug?: string) => {
    _loading(true);

    try {
      const params = {
        "pagination[page]": 1,
        "pagination[pageSize]": 50,
        "filter[category_slugs][0]": categorySlug,
        "includes[attachments]": "true",
        ...(productSlug ? { "filter[slugs][0]": productSlug } : {}),
      };

      const response = await service(
        "GET",
        `/ecommerce/products/search/1/stage-gear`,
        params
      );

      _products(response?.["result"]);
    } catch (error) {
      console.error(error);
    } finally {
      _loading(false);
    }
  };

  const checkProductAvailability = async (
    productId: number,
    pickupDate: string,
    returnDate: string,
    isPuchasable?: boolean
  ) => {
    _checking(true);

    try {
      await service(
        "GET",
        `/ecommerce/products/stage-gear/${productId}/check-availability`,
        {
          startDate: pickupDate,
          endDate: isPuchasable ? "9999-01-01" : returnDate,
        }
      );
    } catch (error) {
      console.error(error);
      throw new Error(
        isPuchasable
          ? "The product cannot be added to cart because it is in shortage."
          : "This item is unavailable for the selected date period."
      );
    } finally {
      _checking(false);
    }
  };

  useEffect(() => {
    if (!categorySlug && !productSlug) return;

    fetchProducts(categorySlug, productSlug);
  }, [categorySlug, productSlug]);

  return {
    products,
    loading: (!error && !products) || loading,
    error,
    checking,
    checkProductAvailability,
  };
};
