import { EditorData, Renderer } from "@with-nx/cms-ui";
import { Accordion, Rule } from "@with-nx/simple-ui/atoms";
import { FC, ReactNode } from "react";
import { Box } from "simple-effing-primitive-layout";

interface ReusableCMSItemAccordionListProps {
  title: string;
  items?: {
    _id?: string;
    title?: string;
    content?: EditorData;
  }[];
  extraHeader?: ReactNode;
  textAlign?: "left" | "center" | "right";
}

export const ReusableCMSItemAccordionList: FC<
  ReusableCMSItemAccordionListProps
> = ({ title, items, extraHeader, textAlign }) => (
  <div className="container">
    <Rule parse={`!hl mb:24 d:block ta:${textAlign || "left"}`}>{title}</Rule>

    {extraHeader && <Box parse="mb:24">{extraHeader}</Box>}

    {items?.map((item, index) => (
      <Accordion
        key={`faq-item-${index}`}
        title={item.title}
        open={index === 0}
      >
        <Renderer data={item.content} />
      </Accordion>
    ))}
  </div>
);
