import { Tooltip } from "@with-nx/editor-ui";
import {
  UseEcommerceOrderItemStatuses,
  UseEcommerceOrdersStatusLabels,
} from "@with-nx/hooks-n-helpers";
import {
  DesignedBadge,
  DesignedButton,
  DesignedIcon,
  Rule,
} from "@with-nx/simple-ui/atoms";
import { Col, Row } from "antd";
import Moment from "moment";
import { useEffect, useState } from "react";
import { FiDownloadCloud } from "react-icons/fi";
import { MdOutlineLightbulb } from "react-icons/md";
import { Box } from "simple-effing-primitive-layout";

import Colors from "../../../../helpers/src/lib/Colors";
import { ActionDropdown } from "../action-dropdown/action-dropdown";
import GenericContentHolder from "../generic-content-holder/generic-content-holder";
import MobileViewSwitcher from "../mobile-view-switcher/mobile-view-switcher";

interface ReusableOrderItemProps {
  mode: "active" | "pending" | "none";
  loading?: boolean;
  open?: boolean;
  image?: string;
  name: string;
  badge: string;
  label?: string;
  bottom?: number;
  date?: string;
  price?: string;
  details?: [string, string][];
  properties?: {
    [key: string]: unknown;
  };
  fit?: "cover" | "contain";
  buttons?: {
    onHelp?: () => unknown;
    onOpenGuides?: () => unknown;
    onOpen?: () => unknown;
    onDownloadCues?: () => unknown;
    onDownload?: () => unknown;
    onDownloadProLicense?: () => unknown;
    onDownloadStagePlayer?: () => unknown;
    onEdit?: () => unknown;
    onEditStagePlayer?: () => unknown;
    onCollection?: () => unknown;
    onOpenCreditCardModal?: () => unknown;
    onOpenPurchaseOrderModal?: () => unknown;
    onReOrderStageGear?: () => unknown;
    onCancel?: () => unknown;
  };
  minimal?: boolean;
  rehearsal?: boolean;
  proLicense?: boolean;
  status?: UseEcommerceOrderItemStatuses;
  canUploadPurchaseOrder?: boolean;
  payments?: unknown[];
}

export const ReusableOrderItem = (props: ReusableOrderItemProps) => {
  const {
    mode,
    image,
    name,
    badge,
    label,
    bottom = 24,
    date,
    price,
    details,
    buttons,
    open,
    minimal,
    fit,
    rehearsal,
    proLicense,
    status,
    canUploadPurchaseOrder,
    payments,
  } = props;

  const [expand, _expand] = useState(open === undefined ? true : open);
  useEffect(() => {
    _expand(open === undefined ? true : open);
  }, [open]);

  const creditCardUpdatePaymentOption = {
    icon: <DesignedIcon size={20} name="card" color="var(--entity)" />,
    label: "Credit Card",
    onClick: buttons?.onOpenCreditCardModal,
  };

  return (
    <MobileViewSwitcher
      width={1199}
      render={(mobile) => (
        <Box n="container" parse="d:flex pa:20 c:#14181D br:10" bottom={bottom}>
          <Box
            n="mode"
            parse="h:50 w:4 br:999 mr:16 mnw:4"
            color={
              mode === "active"
                ? Colors.positive
                : mode === "pending"
                ? Colors.warning
                : Colors.entity
            }
          ></Box>

          <Box n="sub-container" parse="f:1">
            <Box
              n="inline-container"
              parse={`w:100% d:flex a:${
                mobile ? "flex-start" : "flex-start"
              } fd:${mobile ? "column" : "row"} fw:wrap`}
            >
              <Box
                n="image-and-title"
                parse={`${mobile ? "f:1" : "f:2"} d:flex a:flex-start w:${
                  mobile ? "100%" : "45%"
                }`}
              >
                {image === "none" ? undefined : (
                  <Box parse="mr:16">
                    <GenericContentHolder
                      fit={fit}
                      parse="w:88 h:50 br:10 oa:hidden c:?foreground"
                      image={image}
                      alt={name}
                    />
                  </Box>
                )}
                <Box css="d:inline-flex fd:column">
                  <Rule rule="lm" color={Colors.font1} bottom={2}>
                    {name}
                  </Rule>

                  <Box parse="d:inline-flex a:center">
                    <Box css="d:inline-flex">
                      {badge ? (
                        <DesignedBadge size="small" label={badge} />
                      ) : undefined}
                    </Box>
                  </Box>
                </Box>
              </Box>

              {minimal ? undefined : (
                <Box
                  n="simple-details"
                  parse={`f:1 d:${minimal ? "none" : "flex"} pl:${
                    mobile ? 0 : 16
                  }  mt:${mobile ? 16 : 0} w:${
                    mobile ? "100%" : 0
                  } a:center j:space-between
                  `}
                >
                  <Box css="d:inline-flex a:flex-start">
                    {rehearsal && (
                      <Box parse="mr:12">
                        <DesignedBadge
                          size="small"
                          label="In Rehearsal"
                          background="var(--primary)"
                          color="var(--accent)"
                        />
                      </Box>
                    )}

                    {date ? (
                      <Box parse="pr:10">
                        <Rule
                          rule="lt"
                          color={Colors.font4}
                          display="block"
                          bottom={5}
                        >
                          {label}
                        </Rule>
                        <Rule rule="lm" color={Colors.font1} display="block">
                          {date
                            ? Moment(date).isValid()
                              ? Moment(date).format("DD MMMM YYYY")
                              : date
                            : ""}
                        </Rule>
                      </Box>
                    ) : undefined}

                    {mobile || !price ? undefined : (
                      <Box parse="pr:10">
                        <Rule
                          rule="lt"
                          color={Colors.font4}
                          display="block"
                          bottom={5}
                        >
                          Price
                        </Rule>
                        <Rule rule="lm" color={Colors.font1} display="block">
                          {price}
                        </Rule>
                      </Box>
                    )}
                  </Box>

                  {status === "AWAITING_PAYMENT" && (
                    <Box
                      parse="d:flex fd:column ml:16"
                      style={{
                        alignSelf: "flex-start",
                      }}
                    >
                      <Box>
                        <Rule
                          rule="lt"
                          color={Colors.font4}
                          display="inline-block"
                          parse="mr:8px"
                          bottom={5}
                        >
                          Status
                        </Rule>

                        <DesignedBadge
                          size="small"
                          color={
                            Number(payments?.length) > 1
                              ? "var(--primary)"
                              : "var(--negative)"
                          }
                          background={
                            Number(payments?.length) > 1
                              ? "var(--tetriary)"
                              : "var(--negative-dark)"
                          }
                          label={
                            Number(payments?.length) > 1
                              ? "Payment Processing"
                              : UseEcommerceOrdersStatusLabels[status]
                          }
                        />

                        <ActionDropdown
                          label="Update Payment"
                          options={
                            canUploadPurchaseOrder
                              ? [
                                  creditCardUpdatePaymentOption,
                                  {
                                    icon: (
                                      <DesignedIcon
                                        size={20}
                                        name="document"
                                        color="var(--entity)"
                                      />
                                    ),
                                    label: "Purchase Order",
                                    onClick: buttons?.onOpenPurchaseOrderModal,
                                  },
                                ]
                              : [creditCardUpdatePaymentOption]
                          }
                          mobile={mobile}
                        />
                      </Box>
                    </Box>
                  )}

                  {minimal ? undefined : (
                    <Box css="d:inline-flex a:center">
                      {buttons?.onOpen ? (
                        <Tooltip
                          position="top-center"
                          label="Open"
                          width={80}
                          textAlign="center"
                        >
                          <DesignedButton
                            icon="external/bold"
                            square={true}
                            size={mobile ? "small" : "large"}
                            theme="tetriary"
                            press={buttons.onOpen}
                            properties={{ left: 10 }}
                          />
                        </Tooltip>
                      ) : undefined}

                      {buttons?.onOpenGuides ? (
                        <Tooltip
                          position="top-center"
                          label="Open Guides"
                          width={100}
                          textAlign="center"
                        >
                          <DesignedButton
                            icon="_guides"
                            square={true}
                            size={mobile ? "small" : "large"}
                            theme="tetriary"
                            press={buttons.onOpenGuides}
                            properties={{ left: 10 }}
                          />
                        </Tooltip>
                      ) : undefined}

                      {buttons?.onDownloadCues ? (
                        <Tooltip
                          position="top-center"
                          label="Download Cue Sheet"
                          width={80}
                          textAlign="center"
                        >
                          <DesignedButton
                            icon={
                              <FiDownloadCloud
                                size={20}
                                color="var(--primary)"
                                title="download icon"
                              />
                            }
                            square={true}
                            size={mobile ? "small" : "large"}
                            theme="tetriary"
                            press={buttons.onDownloadCues}
                            properties={{ left: 10 }}
                          />
                        </Tooltip>
                      ) : undefined}

                      {buttons?.onDownload ? (
                        <Tooltip
                          position="top-center"
                          label="Download"
                          width={80}
                          textAlign="center"
                        >
                          <DesignedButton
                            icon={
                              <FiDownloadCloud
                                size={20}
                                color="var(--primary)"
                              />
                            }
                            square={true}
                            size={mobile ? "small" : "large"}
                            theme="tetriary"
                            press={buttons.onDownload}
                            properties={{ left: 10 }}
                          />
                        </Tooltip>
                      ) : undefined}

                      {buttons?.onDownloadStagePlayer ? (
                        <>
                          {proLicense && (
                            <Tooltip
                              position="top-center"
                              label="Download License"
                              textAlign="center"
                            >
                              <DesignedButton
                                icon="_proLicense/regular"
                                square={true}
                                size={mobile ? "small" : "large"}
                                theme="tetriary"
                                press={buttons.onDownloadProLicense}
                                properties={{ left: 10 }}
                                configuration={{
                                  icon: {
                                    size: mobile ? 26 : 32,
                                  },
                                }}
                              />
                            </Tooltip>
                          )}

                          <Tooltip
                            position="top-center"
                            label="Download Stage Player"
                            width={160}
                            textAlign="center"
                          >
                            <DesignedButton
                              icon="_download"
                              square={true}
                              size={mobile ? "small" : "large"}
                              theme="tetriary"
                              press={buttons.onDownloadStagePlayer}
                              properties={{ left: 10 }}
                            />
                          </Tooltip>
                        </>
                      ) : undefined}

                      {buttons?.onReOrderStageGear ? (
                        <Tooltip
                          position="top-center"
                          label="Re-Order StageGear"
                          width={160}
                          textAlign="center"
                        >
                          <DesignedButton
                            icon="add_to_cart"
                            square={true}
                            size={mobile ? "small" : "large"}
                            theme="tetriary"
                            press={buttons.onReOrderStageGear}
                            properties={{ left: 10 }}
                          />
                        </Tooltip>
                      ) : undefined}

                      {buttons?.onCollection ? (
                        <Tooltip
                          position="top-center"
                          label="Edit Collection"
                          width={80}
                          textAlign="center"
                        >
                          <DesignedButton
                            icon={
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.33333 2.5H2.5V8.33333H8.33333V2.5Z"
                                  stroke="#E2B576"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M17.5 2.5H11.6667V8.33333H17.5V2.5Z"
                                  stroke="#E2B576"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M17.5 11.6667H11.6667V17.5H17.5V11.6667Z"
                                  stroke="#E2B576"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M8.33333 11.6667H2.5V17.5H8.33333V11.6667Z"
                                  stroke="#E2B576"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            }
                            square={true}
                            size={mobile ? "small" : "large"}
                            theme="tetriary"
                            press={buttons?.onCollection}
                            properties={{ left: 10 }}
                          />
                        </Tooltip>
                      ) : undefined}

                      {buttons?.onEdit ? (
                        <Tooltip
                          position="top-center"
                          label="Edit"
                          width={80}
                          textAlign="center"
                        >
                          <DesignedButton
                            icon="pencil/bold"
                            square={true}
                            size={mobile ? "small" : "large"}
                            theme="tetriary"
                            press={buttons.onEdit}
                            properties={{ left: 10 }}
                          />
                        </Tooltip>
                      ) : undefined}

                      {/* {buttons?.onEditStagePlayer ? (
                      <Tooltip
                        position="top-center"
                        label="Edit Stage Player"
                        width={80}
                        textAlign="center"
                      >
                        <DesignedButton
                          icon="_editor"
                          square={true}
                          size={mobile ? "small" : "large"}
                          theme="tetriary"
                          press={buttons.onEditStagePlayer}
                          properties={{ left: 10 }}
                        />
                      </Tooltip>
                      ) : undefined} */}

                      <DesignedButton
                        icon={expand ? "up/bold" : "down/bold"}
                        square={true}
                        size={mobile ? "small" : "large"}
                        theme="secondary"
                        press={() => _expand(!expand)}
                        properties={{ left: 10 }}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </Box>

            {/* Expanded section */}
            {expand ? (
              minimal ? null : (
                <Box
                  n="expanded-details"
                  parse="mt:16"
                  style={{ borderTop: "1px solid var(--accent)" }}
                >
                  <Box n="row-cols">
                    <Row gutter={[32, 0]}>
                      {details?.map(([label, value], i) => (
                        <Col xs={12} sm={8} md={6} lg={4} key={i}>
                          <Rule
                            rule="lt"
                            color={Colors.font4}
                            display="block"
                            top={16}
                            bottom={5}
                          >
                            {label}
                          </Rule>
                          <Rule rule="lm" color={Colors.font1} display="block">
                            {value}
                          </Rule>
                        </Col>
                      ))}
                    </Row>
                  </Box>
                  <Box parse="d:flex a:center mt:16">
                    {buttons?.onHelp ? (
                      <Box
                        n="have-questions-cta"
                        parse="mr:16 d:inline-flex"
                        press={buttons?.onHelp}
                      >
                        <DesignedIcon
                          name="info/bold"
                          size={16}
                          color={Colors.primary}
                        />
                        <Rule rule="ls" color={Colors.primary} left={5}>
                          Have questions about the order?
                        </Rule>
                      </Box>
                    ) : undefined}
                    {buttons?.onCancel ? (
                      <Box
                        n="have-questions-cta"
                        parse="mr:16 d:inline-flex"
                        press={buttons?.onCancel}
                      >
                        <Rule rule="ls" color={Colors.font3} left={5}>
                          Request order cancellation
                        </Rule>
                      </Box>
                    ) : undefined}
                  </Box>

                  {rehearsal ? (
                    <Box parse="c:var(--primary) pa:12 br:10 d:flex mt:16">
                      <MdOutlineLightbulb
                        size="24"
                        color="var(--foreground)"
                        title="light bulb icon"
                      />
                      Your rehearsal material is ready to download. You will
                      have access to the watermarked version.
                      <br />
                      The watermarks will disappear and you will have full
                      access at {date}.
                    </Box>
                  ) : undefined}
                </Box>
              )
            ) : undefined}
          </Box>
        </Box>
      )}
    />
  );
};

export default ReusableOrderItem;
