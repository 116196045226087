/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @version 7
 */
const CACHES_TABLE = "@database/caches";

export class Database {
  private static _version = 9;
  private static _name = "@database/caches/table";
  private static _db: IDBDatabase | null | undefined = null;

  static async bootstrap(debug?: boolean) {
    return await new Promise((resolve) => {
      if (!("indexedDB" in window)) {
        throw new Error("This browser doesn't support IndexedDB");
      }

      const request = indexedDB.open(this._name, this._version);

      request.onerror = (event: any) => {
        console.error("📦", event?.target?.errorCode);
        resolve(null);
      };

      request.onsuccess = (event: any) => {
        this._db = event?.target?.result;

        resolve(event?.target?.result);
      };

      request.onupgradeneeded = (event: any) => {
        const result = event?.target?.result as IDBDatabase;
        const old = event?.oldVersion;

        this._db = result;

        if (old < 7) {
          this._db?.createObjectStore(CACHES_TABLE);
        }
        resolve(result);
      };
    });
  }

  static async get<T = unknown>(
    key: string,
    account?: string,
    debug?: boolean
  ): Promise<T | null | void> {
    try {
      let db = this._db;

      if (!db) {
        db = (await this.bootstrap(debug)) as IDBDatabase;
      }

      return await new Promise((resolve, reject) => {
        if (!db) {
          return;
        }

        const transaction = db.transaction([CACHES_TABLE]);
        const objectStore = transaction.objectStore(CACHES_TABLE);
        const request = objectStore.get(key);

        request.onerror = (event: any) => {
          reject(event.target.errorCode);
        };

        request.onsuccess = (event: any) => {
          const data = event.target.result;

          if (!data) {
            resolve(null);
            return;
          }

          const { value, expires } = data;

          if (account && account !== data.account) {
            resolve(null);
            return;
          }

          if (expires < Date.now()) {
            resolve(null);
          }

          resolve(value);
          return;
        };
      });
    } catch (error) {
      console.error(error);
      return;
    }
  }

  static set(
    key: string,
    value: unknown,
    ttl = 60 * 60 * 1000,
    account?: string
  ) {
    try {
      if (!this._db) {
        this.bootstrap();
      }

      return new Promise((resolve, reject) => {
        if (!this._db) {
          return;
        }

        const transaction = this._db.transaction([CACHES_TABLE], "readwrite");
        const objectStore = transaction.objectStore(CACHES_TABLE);
        const request = objectStore.put(
          {
            key,
            value,
            expires: Date.now() + ttl,
            account,
          },
          key
        );

        request.onerror = (event: any) => {
          reject(event.target.errorCode);
        };

        request.onsuccess = (event: any) => {
          resolve(event.target.result);
        };
      });
    } catch (error) {
      console.error(error);
      return;
    }
  }

  static remove(partialKey: string) {
    try {
      return new Promise<void>((resolve, reject) => {
        if (!this._db) {
          return;
        }

        const transaction = this._db.transaction([CACHES_TABLE], "readwrite");
        const objectStore = transaction.objectStore(CACHES_TABLE);

        const request = objectStore.openKeyCursor();

        request.onerror = (event: any) => {
          reject(event.target.errorCode);
        };

        request.onsuccess = (event: any) => {
          const cursor = event.target.result;

          if (cursor && cursor.key.includes(partialKey)) {
            objectStore.delete(cursor.key);
            cursor.continue();
          } else {
            resolve();
          }
        };
      });
    } catch (error) {
      console.error(error);
      return;
    }
  }
}

export default Database;
