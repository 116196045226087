import useService from "../useService";
import { GatedContentInteractions } from "./GatedContentInteractions";

export class GatedContentUnlock {
  static async unlock(code: string, token?: string) {
    const makeRequestToCMS = useService("cms", {
      cache: 86_400_000,
      bypass: true,
    });

    const json = (await makeRequestToCMS(
      "GET",
      `/api/v1/gated-content-unlock/unlock/${code}`,
      {},
      token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : undefined
    )) as {
      redirect?: string;
    };

    if (json?.redirect) {
      GatedContentInteractions.save(
        {
          page: json.redirect,
          content: "Sharing",
          access: "Unlock",
          metadata: {
            code,
            redirect: json.redirect,
          },
        },
        token
      );
      return json.redirect;
    }

    return false;
  }

  static async share(token: string, redirect: string) {
    const makeRequestToCMS = useService("cms", {
      cache: 86_400_000,
      bypass: true,
    });

    const path = (() => {
      if (typeof window !== "undefined") {
        return window.location.pathname;
      } else {
        return "Unknown Page";
      }
    })();

    const json = (await makeRequestToCMS(
      "POST",
      `/api/v1/gated-content-unlock/share`,
      {
        redirect,
      },
      {
        Authorization: `Bearer ${token}`,
      }
    )) as {
      code?: string;
    };

    if (json?.code) {
      GatedContentInteractions.save(
        {
          page: path,
          content: "Sharing",
          access: "Share",
          metadata: {
            code: json.code,
            redirect,
          },
        },
        String(token)
      );
      return json.code;
    }

    return false;
  }
}
