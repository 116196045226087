import { Product, WithContext } from "schema-dts";

export const GoogleSchema = ({
  data,
}: {
  data: {
    id?: string;
    name: string;
    description?: string;
    currency: string;
    unit?: string;
    price: number | string;
    image?: string;
  };
}) => {
  if (!data?.image) {
    /* Not having an image is a critical issue. But our images are controlled dynamically based on licensing reasons. Thus, we'll prevent this component from being visible dynamically. */
    return null;
  }

  try {
    const ld: WithContext<Product> = {
      "@context": "https://schema.org",
      "@type": "Product",
      productID: data?.id,
      name: data.name,
      description: data.description,
      image: data.image,
      offers: {
        "@type": "Offer",
        priceCurrency: data.currency,
        price: String(data.price),
        availability: "https://schema.org/InStock",
        priceSpecification: !data?.unit
          ? undefined
          : {
              "@type": "UnitPriceSpecification",
              priceCurrency: data.currency,
              price: String(data.price),
              unitText: data.unit,
            },
      },
    };

    return (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(ld) }}
      />
    );
  } catch (error) {
    console.error(error);
    return null;
  }
};
