import { useServerRegion } from "@with-nx/region";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

const DynamicElements = dynamic(
  () => import("@stripe/react-stripe-js").then((l) => l.Elements),
  {
    ssr: false,
  }
);

interface StripeGateProps {
  children: JSX.Element;
}

export const StripeGate = ({ children }: StripeGateProps) => {
  const [active, _active] = useState(false);
  const router = useRouter();
  const region = useServerRegion();

  useEffect(() => {
    const path = window.location.pathname;

    if (path.includes("/checkout") || path.includes("/profile/order-history")) {
      _active(true);
    }

    router.events.on("routeChangeComplete", () => {
      const path = window.location.pathname;

      if (
        path.includes("/checkout") ||
        path.includes("/profile/order-history")
      ) {
        _active(true);
      }
    });
  }, []);

  if (!active) {
    return children;
  }

  const promise = import("@stripe/stripe-js").then((stripe) => {
    const currency = region?.currency?.code?.toLocaleLowerCase();

    const token = (() => {
      switch (currency) {
        case "usd":
          return process.env["NEXT_PUBLIC_STRIPE_US_PUBLISHABLE_KEY"] || "";
        case "gbp":
          return process.env["NEXT_PUBLIC_STRIPE_GB_PUBLISHABLE_KEY"] || "";
        case "aud":
          return process.env["NEXT_PUBLIC_STRIPE_AU_PUBLISHABLE_KEY"] || "";
        default:
          return process.env["NEXT_PUBLIC_STRIPE_US_PUBLISHABLE_KEY"] || "";
      }
    })();

    return stripe.loadStripe(token);
  });

  return (
    <DynamicElements
      options={{
        fonts: [
          {
            family: "Figtree",
            src: "url(https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap)",
            weight: "600",
          },
        ],
      }}
      stripe={promise}
    >
      {children}
    </DynamicElements>
  ) as JSX.Element;
};

export default StripeGate;
