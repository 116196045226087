import { useVisible } from "@with-nx/hooks-n-helpers";
import { createRef } from "react";
import { Box } from "simple-effing-primitive-layout";

export const VisibleContainer = ({
  children,
  debug,
}: {
  children: JSX.Element | JSX.Element[];
  debug?: string;
}) => {
  const reference = createRef<HTMLDivElement>();
  const { visible } = useVisible(reference, true);

  return (
    <div ref={reference}>
      {visible ? (
        children
      ) : (
        <Box parse="h:200 d:flex a:center j:center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            preserveAspectRatio="xMidYMid"
            className="-simple-ui-designed-button-transition"
          >
            <circle
              cx="8"
              cy="8"
              fill="none"
              stroke="rgba(255,255,255,0.5)"
              strokeWidth="2"
              r="6"
              strokeDasharray="28.274333882308138 11.42477796076938"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                repeatCount="indefinite"
                dur="1s"
                values="0 8 8;360 8 8"
                keyTimes="0;1"
              ></animateTransform>
            </circle>
          </svg>
        </Box>
      )}
    </div>
  );
};

export default VisibleContainer;
